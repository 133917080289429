import React, { useCallback, useEffect, useState } from 'react';

import { Container, Grid } from '@mui/material';
import { MediaFile, RPMessage, THQPrimaryButton } from '@trainhq/trainhq-client-core';
import { useNavigate, useSearchParams } from 'react-router-dom';

import RPContextNarrator from '@/assets/images/rp_context_narrator.png';
import { ROLEPLAYS } from '@/constants/router';
import { useAuthenticatedUserContext } from '@/context/providers/AuthUserProvider';
import { useAzureSDKContext } from '@/hooks/azureSDK/useAzureSDKContext';
import CallRolePlay from '@/roleplay/naturalRolePlay/callRolePlay/CallRolePlay';
import TitleCard from '@/roleplay/naturalRolePlay/common/titleCard/TitleCard';
import XButton from '@/roleplay/naturalRolePlay/common/xButton/XButton';
import { createFormRoleplayContext, TTSReadMessage } from '@/roleplay/naturalRolePlay/utils';
import { RecordingSpeaker } from '@/services/repositoryData/cloud/dto/roleplayCloudDtos';

interface CallRolePlayStarterProps {
  chatMessages: any[];
  coveredSteps: any[];
  initialSentence?: boolean;
  sessionUuid: string;
  determineErrorHandling(error: any): void;
  init(): void;
  pauseBot(): void;
  readMessage(readMessageProps: TTSReadMessage): void;
  setChatMessages: React.Dispatch<React.SetStateAction<any[]>>;
  setCoveredSteps: React.Dispatch<React.SetStateAction<any[]>>;
  toggleValidationDialog(props?: { onBeforeSubmit?: () => void; onClose?: () => void; onRestart?: () => void }): void;
  uploadAndSaveRecording(mediaFile: MediaFile, speaker: RecordingSpeaker): void;
}

const CallRolePlayStarter: React.FC<CallRolePlayStarterProps> = ({
  chatMessages,
  coveredSteps,
  initialSentence,
  sessionUuid,
  determineErrorHandling,
  init,
  pauseBot,
  readMessage,
  setChatMessages,
  setCoveredSteps,
  toggleValidationDialog,
  uploadAndSaveRecording
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const returnTo = searchParams.get('returnTo');

  const user = useAuthenticatedUserContext();
  const { roleplayConfig, roleplayContext } = useAzureSDKContext();

  const handleGoBackToRoleplays = useCallback(() => {
    if (returnTo) {
      navigate(returnTo);
    } else {
      navigate(ROLEPLAYS);
    }
  }, [navigate, returnTo]);

  const [rolePlayEntered, setRolePlayEntered] = useState<boolean>(false);

  const handleToggleEnterRoleplay = () => {
    setRolePlayEntered((prevState) => !prevState);
  };

  // start session for billing and initialize the roleplay
  useEffect(() => {
    init();
  }, [init]);

  return rolePlayEntered ? (
    <CallRolePlay
      chatMessages={chatMessages}
      coveredSteps={coveredSteps}
      initialSentence={initialSentence}
      sessionUuid={sessionUuid}
      determineErrorHandling={determineErrorHandling}
      onBackButtonClick={handleGoBackToRoleplays}
      pauseBot={pauseBot}
      readMessage={readMessage}
      setChatMessages={setChatMessages}
      setCoveredSteps={setCoveredSteps}
      toggleValidationDialog={toggleValidationDialog}
      uploadAndSaveRecording={uploadAndSaveRecording}
    />
  ) : (
    <Container fixed>
      <TitleCard fixed name={roleplayConfig?.name} />
      <XButton onClick={handleGoBackToRoleplays} />
      <Grid
        container
        height="100vh"
        justifyContent="center"
        marginLeft="auto"
        marginRight="auto"
        maxWidth="sm"
        paddingBottom="104px"
        paddingTop="147px"
        position="relative"
      >
        <Grid item height="calc(100vh - 147px - 104px)">
          {roleplayContext && (
            <RPMessage
              lessImportant
              maxExpand
              avatar={RPContextNarrator}
              key={'' + 'roleplayContext_intro'}
              content={'Hi! I’m your TrainHQ Assistant. I’m here to share some context about this role-play.'}
              highlight={false}
              primary={false}
              side={'left'}
              user={user}
            />
          )}
          {roleplayContext && (
            <RPMessage
              lessImportant
              maxExpand
              avatar={RPContextNarrator}
              key={'' + 'roleplayContext'}
              content={createFormRoleplayContext(roleplayContext)}
              highlight={false}
              primary={false}
              side={'left'}
              user={user}
            />
          )}
          <RPMessage
            lessImportant
            maxExpand
            avatar={RPContextNarrator}
            key={'' + 'roleplayContext_outro'}
            content={'Whenever you are ready click on the button to start the role-play. Good luck!'}
            highlight={false}
            primary={false}
            side={'left'}
            user={user}
          />
        </Grid>
        <THQPrimaryButton
          size="medium"
          sx={{ bottom: '48px', position: 'absolute' }}
          variant="main"
          onClick={handleToggleEnterRoleplay}
        >
          Enter role-play
        </THQPrimaryButton>
      </Grid>
    </Container>
  );
};

export default CallRolePlayStarter;
