import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Box, Grid, SelectChangeEvent } from '@mui/material';
import {
  isOpenaiVoice,
  RolePlayModePhase,
  RPMessage,
  SpeedSelect,
  THQPrimaryButton,
  useTextToSpeech,
  XIcon
} from '@trainhq/trainhq-client-core';
import type * as SpeechSDKType from 'microsoft-cognitiveservices-speech-sdk/distrib/lib/microsoft.cognitiveservices.speech.sdk';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { ReactComponent as Skip } from '@/assets/icons/skip.svg';
import BotImageFemale from '@/assets/images/bot_female.png';
import BotImageMale from '@/assets/images/bot_male.png';
import RPContextNarrator from '@/assets/images/rp_context_narrator.png';
import UserImageFemale from '@/assets/images/user_female.png';
import UserImageMale from '@/assets/images/user_male.png';
import { TEXT_TO_SPEECH } from '@/constants/api';
import { ROLEPLAYS } from '@/constants/router';
import { useAuthenticatedUserContext } from '@/context/providers/AuthUserProvider';
import { useAzureSDKContext } from '@/hooks/azureSDK/useAzureSDKContext';
import { useRolePlayService } from '@/hooks/roleplay/useRolePlayService';
import ErrorInSpeechSynthesisDialog from '@/roleplay/components/errorInSpeechSynthesisDialog/ErrorInSpeechSynthesisDialog';
import { RPRunSummary } from '@/roleplay/components/rpRunSummary/RPRunSummary';
import SkipWatchDialog from '@/roleplay/components/skipWatchDialog/SkipWatchDialog';
import { getNextPhase } from '@/roleplay/naturalRolePlay/utils';
import WatchModeGoalsAndObjectionsPanel from '@/roleplay/naturalRolePlay/watchMode/goalsPanel/WatchModeGoalsAndObjectionsPanel';
import { RPChatContainer, RPContainer } from '@/roleplay/styles';

const perWordDelay = 10;
const baselineDelay = 400;

interface WatchModeRunnerProps {
  initializing: boolean;
  setInitializing: React.Dispatch<React.SetStateAction<boolean>>;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
  backToRoot: () => void;
}

const WatchModeRunner: React.FC<WatchModeRunnerProps> = ({ backToRoot, initializing, setInitializing, setError }) => {
  const navigate = useNavigate();
  const rpService = useRolePlayService();
  const [searchParams] = useSearchParams();
  const returnTo = searchParams.get('returnTo');
  const [chatMessages, setChatMessages] = useState([]);
  const chatContainer = useRef<HTMLDivElement>(null);
  const [fadeChat, setFadeChat] = useState(false);
  const [sessionStarted, setSessionStarted] = useState(false);
  const [currentMessage, setCurrentMessage] = useState(0);
  const [scriptDone, setScriptDone] = useState(false);
  const mainScheduler = useRef(null);
  const [skipWatchOpen, setSkipWatchOpen] = useState(false);
  const user = useAuthenticatedUserContext();

  const [playEnabled, setPlayEnabled] = useState(false);
  const play = useRef(false);
  const playerRef = useRef<SpeechSDKType.SpeakerAudioDestination>(null);
  const skipped = useRef<boolean>(false);
  const [ttsSpeed, setTtsSpeed] = useState<string>('1.0');
  const [errorInSpeechSynthesis, setErrorInSpeechSynthesis] = useState(false);

  const tts = useTextToSpeech({ url: TEXT_TO_SPEECH });

  const {
    getSynth,
    getPlayer,
    getSsmlMessage,
    roleplayExecutionConfig,
    roleplayContext,
    roleplayConfig,
    scriptConfig,
    roleplaySummary,
    refreshRoleplaySummary,
    setRoleplaySummary,
    roleplayPairContextMedia
  } = useAzureSDKContext();

  const goals = useMemo(() => {
    if (roleplayExecutionConfig?.rolePlaySections) {
      const sectionsToRender = roleplayExecutionConfig?.rolePlaySections?.sort((a, b) => a.order - b.order);
      sectionsToRender.map((section) => {
        return { ...section, goals: section.goals.sort((a, b) => a.order - b.order) };
      });
      return sectionsToRender;
    }
    if (scriptConfig?.jsonGoals) {
      return JSON.parse(scriptConfig.jsonGoals);
    }
    return null;
  }, [scriptConfig?.jsonGoals, roleplayExecutionConfig?.rolePlaySections]);

  const objections = useMemo(() => {
    if (roleplayExecutionConfig?.rolePlayObjections) {
      return roleplayExecutionConfig?.rolePlayObjections?.sort((a, b) => a.order - b.order);
    }
    if (!scriptConfig?.objectionsBank) {
      return null;
    }
    return JSON.parse(scriptConfig.objectionsBank);
  }, [scriptConfig?.objectionsBank, roleplayExecutionConfig?.rolePlayObjections]);

  const handleFinishWatchMode = () => {
    rpService
      .setRolePlayPhase({
        journeyUuid: roleplaySummary.journeyUuid,
        roleplayUuid: roleplayConfig.rolePlayUuid,
        rolePlayModePhase: getNextPhase(RolePlayModePhase.WATCH)
      })
      .subscribe((res) => {
        setRoleplaySummary(res);
        if (backToRoot) {
          backToRoot();
        }
      });
  };

  const script = useMemo(() => {
    if (roleplayExecutionConfig.baseline && roleplayExecutionConfig.baseline.length > 0) {
      const lines = [];
      roleplayExecutionConfig.baseline.forEach((item) => {
        if (item.learner) {
          lines.push({ role: 'me', content: item.learner.message });
        }
        lines.push({ role: 'bot', content: item.bot.message });
      });
      if (roleplayConfig.starterMessage) {
        lines.unshift({ role: 'bot', content: roleplayConfig.starterMessage });
      }
      return lines;
    } else {
      const normalizedString = scriptConfig.conversationalPrompt.normalize();
      const regexSearch = /'''((?:.|\n)*)'''/gm.exec(scriptConfig.conversationalPrompt.normalize());
      const substringMethod = normalizedString.substring(
        normalizedString.indexOf("'''"),
        normalizedString.lastIndexOf("'''")
      );
      const extractedBaseline = regexSearch && regexSearch[1] ? regexSearch[1] : substringMethod;
      if (extractedBaseline) {
        const lines = extractedBaseline
          .split('\n')
          ?.filter((item) => !!item)
          ?.filter((item) => item.match(/[a-zA-z ]+?:/))
          .map((item) => {
            if (item.includes('Learner') || item.includes('learner')) {
              return { role: 'me', content: item.substring(item.indexOf(':') + 1, item.length) };
            } else {
              return { role: 'bot', content: item.substring(item.indexOf(':') + 1, item.length) };
            }
          });
        return lines;
      }
      return null;
    }
  }, [scriptConfig?.conversationalPrompt, roleplayExecutionConfig?.baseline]);

  const speaker = useMemo(() => script[currentMessage]?.role, [currentMessage, script]);
  const voiceInUse = speaker === 'me' ? roleplayConfig.learnerVoice : roleplayConfig.botVoice;

  const botImage = searchParams.get('bot') ?? roleplayConfig?.botImage ?? 'f';
  const userImage = searchParams.get('user') ?? roleplayConfig?.userImage ?? 'm';

  const pausePlayback = () => {
    if (isOpenaiVoice(voiceInUse)) {
      tts.pause();
    } else {
      playerRef.current?.pause();
    }
  };

  const resumePlayback = () => {
    playerRef.current?.resume();
  };

  const startPlay = () => {
    setPlayEnabled(true);
    play.current = true;
    skipped.current = false;
  };

  const stopPlay = () => {
    play.current = false;
    setPlayEnabled(false);
  };

  const readMessage = useCallback(
    ({ message, speaker, expression }: { message: string; speaker: string; expression?: string }) => {
      const voiceInUse = speaker === 'me' ? roleplayConfig.learnerVoice : roleplayConfig.botVoice;

      if (isOpenaiVoice(voiceInUse)) {
        tts.play({
          message,
          voice: voiceInUse,
          speed: ttsSpeed,
          onErrorCallback: () => {
            setErrorInSpeechSynthesis(true);
          },
          callback: () => {
            if (!skipped.current) {
              setChatMessages((prev) => [{ content: message, sender: speaker }, ...prev]);
            } else {
              pausePlayback();
              skipped.current = false;
            }
          }
        });
        if (tts.audioPlayer) {
          tts.audioPlayer.onended = () => {
            setCurrentMessage((prev) => {
              enqueueMessageToChat(script[prev + 1]?.content, script[prev + 1]?.role, baselineDelay);
              return prev + 1;
            });
          };
        }
      } else {
        getSynth().subscribe({
          next: (synth) => {
            getPlayer().subscribe((player) => {
              playerRef.current = player;
              player.onAudioStart = (sender) => {
                if (!skipped.current) {
                  setChatMessages((prev) => [{ content: message, sender: speaker }, ...prev]);
                } else {
                  pausePlayback();
                  skipped.current = false;
                }
              };
              player.onAudioEnd = (sender) => {
                if (play.current) {
                  setCurrentMessage((prev) => {
                    enqueueMessageToChat(script[prev + 1]?.content, script[prev + 1]?.role, baselineDelay);
                    return prev + 1;
                  });
                }
              };
            });
            synth.speakSsmlAsync(
              getSsmlMessage(message, expression ?? 'general', voiceInUse, ttsSpeed),
              function (result) {
                if (result.reason === window.SpeechSDK.ResultReason.Canceled) {
                  console.error('synthesis failed. Error detail: ' + result.errorDetails + '\n');
                }
                synth.close();
              },
              function (err) {
                window.console.error(err);
              }
            );
          }
        });
      }
    },
    [voiceInUse, ttsSpeed, getPlayer, getSsmlMessage, getSynth, script]
  );

  const enqueueMessageToChat = useCallback(
    (hintMessage, speaker, time?, noVoice?: boolean) => {
      if (!skipped.current) {
        if (!hintMessage) {
          console.log('done!');
          setScriptDone(true);
        } else {
          setChatMessages((previousChatMessages) => [
            {
              content: '',
              sender: speaker
            },
            ...previousChatMessages
          ]);
          clearTimeout(mainScheduler.current);

          mainScheduler.current = setTimeout(
            () => {
              if (noVoice) {
                setChatMessages((previousChatMessages) => [
                  { content: hintMessage, sender: speaker },
                  ...previousChatMessages
                ]);
              } else {
                readMessage({ message: hintMessage, speaker, expression: 'general' });
              }
            },
            time + hintMessage.trim().split(/\s+/).length * perWordDelay
          );
        }
      } else {
        skipped.current = false;
        setScriptDone(false);
      }
    },
    [readMessage]
  );

  // useEffect(() => {
  //   if (script[currentMessage] && play) {
  //     console.log('useEffectCalled');
  //     enqueueMessageToChat(script[currentMessage]?.content, script[currentMessage]?.role, baselineDelay);
  //   }
  // }, [currentMessage, enqueueMessageToChat, script, play]);

  const playScript = useCallback(() => {
    startPlay();
    console.log(roleplayExecutionConfig);
    enqueueMessageToChat(script[currentMessage]?.content, speaker, baselineDelay);
  }, [roleplayExecutionConfig, enqueueMessageToChat, script, currentMessage, speaker]);

  const pauseScript = () => {
    stopPlay();
    setCurrentMessage((prev) => prev + 1);
  };

  const restartScript = () => {};

  const scrollOnEvent = () => {
    const target = chatContainer.current;
    setTimeout(() => {
      target.scroll({ top: target.scrollHeight + 200, behavior: 'smooth' });
    }, 120);
  };

  useEffect(() => {
    setInitializing(false);
  }, [getPlayer, setInitializing]);

  const handleGoBackToRoleplays = useCallback(() => {
    if (returnTo) {
      navigate(returnTo);
    } else {
      navigate(ROLEPLAYS);
    }
  }, [navigate, returnTo]);

  const getMessageAvatar = (identifier: string) => {
    switch (identifier) {
      case 'me':
        return getUserImage();
      case 'narrator':
        return RPContextNarrator;
      case 'bot':
      default:
        return getBotImage();
    }
  };
  const getUserImage = useCallback(() => {
    if (userImage.length > 2) {
      return userImage;
    }
    return userImage === 'm' ? UserImageMale : UserImageFemale;
  }, [userImage]);

  const getBotImage = useCallback(() => {
    if (botImage.length > 2) {
      return botImage;
    }
    return botImage === 'm' ? BotImageMale : BotImageFemale;
  }, [botImage]);

  const restartWatchMode = () => {
    setChatMessages([]);
    setCurrentMessage(0);
    stopPlay();
    setErrorInSpeechSynthesis(false);
    setScriptDone(false);
  };

  const toggleSkipWatchDialog = useCallback(() => {
    setSkipWatchOpen((prev) => !prev);
  }, []);

  const toggleErrorInSpeechSynthesisDialog = useCallback(() => {
    setErrorInSpeechSynthesis((prev) => !prev);
  }, []);

  const skipWatching = () => {
    pausePlayback();
    stopPlay();
    setScriptDone(true);
    skipped.current = true;
    if (script) {
      setChatMessages(script.map((item) => ({ content: item.content, sender: item.role })).reverse());
    }
    setSkipWatchOpen(false);
    setErrorInSpeechSynthesis(false);
  };

  const handleOnChangeSpeedSelect = useCallback((event: SelectChangeEvent) => {
    setTtsSpeed(event.target.value);
  }, []);

  return (
    <>
      <SkipWatchDialog open={skipWatchOpen} handleOnClose={toggleSkipWatchDialog} handleOnConfirm={skipWatching} />
      <ErrorInSpeechSynthesisDialog
        open={errorInSpeechSynthesis}
        handleOnClose={toggleErrorInSpeechSynthesisDialog}
        handleOnConfirm={skipWatching}
        handleOnConfirmSecondary={restartWatchMode}
      />
      {!initializing && (
        <RPContainer>
          <Grid container columnGap={'16px'}>
            <Grid item xs={'auto'}>
              <WatchModeGoalsAndObjectionsPanel
                rolePlayName={roleplayConfig?.name}
                goals={goals}
                objections={objections}
                roleplayConfig={roleplayConfig}
                isWatchMode={true}
              />
            </Grid>
            <Grid item xs={true}>
              <div>
                <THQPrimaryButton sx={{ position: 'fixed', top: '40px', right: '40px' }} onClick={backToRoot}>
                  <XIcon style={{ width: '16px', height: '16px' }} />
                </THQPrimaryButton>

                <RPChatContainer ref={chatContainer} fadeChat={fadeChat}>
                  <RPRunSummary
                    errorInCommunication={false}
                    logHintEvent={undefined}
                    failPresent={false}
                    actualMessage={'lastUserInput'}
                    preferredMessage={'inputHint'}
                    onMainClick={handleFinishWatchMode}
                    chatStatus={'watchModeDone'}
                    showHint={false}
                    onSecondaryClick={restartWatchMode}
                    onRetryLast={undefined}
                    visible={scriptDone}
                    roleplayConfig={roleplayConfig}
                    roleplaySummary={roleplaySummary}
                  />

                  {sessionStarted && (
                    <RPMessage
                      avatar={getUserImage()}
                      content={''}
                      highlight={true}
                      primary={false}
                      side={'right'}
                      user={user}
                    />
                  )}

                  {chatMessages
                    .filter((item, index) => item.content || index === 0)
                    .map((cm, index) => (
                      <RPMessage
                        avatar={getMessageAvatar(cm.sender)}
                        key={'' + cm.uuid + '-' + (cm.content ?? '') + (cm.media?.uuid ? cm.media?.uuid : '' + index)}
                        media={cm.media}
                        introText={cm.introText}
                        content={cm.content}
                        highlight={cm.highlight}
                        primary={cm?.primary !== undefined ? cm?.primary : cm.sender === 'me'}
                        side={cm.sender === 'me' ? 'right' : 'left'}
                        user={user}
                      />
                    ))}

                  <RPMessage
                    lessImportant
                    maxExpand
                    avatar={RPContextNarrator}
                    key={'' + 'roleplayContext_intro3'}
                    content={`When you’re ready, press “Start”. 😎`}
                    highlight={false}
                    primary={false}
                    side={'left'}
                    user={user}
                  />
                  <RPMessage
                    lessImportant
                    maxExpand
                    avatar={RPContextNarrator}
                    key={'' + 'roleplayContext_intro2'}
                    content={`Please turn your volume on to hear the actors’ voices. 🔈`}
                    highlight={false}
                    primary={false}
                    side={'left'}
                    user={user}
                  />

                  <RPMessage
                    lessImportant
                    maxExpand
                    avatar={RPContextNarrator}
                    key={'' + 'roleplayContext_intro1'}
                    content={`During the role-play, ${roleplayConfig?.userName} will complete certain goals. You’ll need to complete those same goals when you practice the role-play. You can find those goals on the left side of the screen. ⬅️`}
                    highlight={false}
                    primary={false}
                    side={'left'}
                    user={user}
                  />
                  <RPMessage
                    lessImportant
                    maxExpand
                    avatar={RPContextNarrator}
                    key={'' + 'roleplayContext_intro'}
                    content={`You’re about to watch a successful role-play example between ${roleplayConfig?.botName} (${roleplayConfig.botTitle}) and ${roleplayConfig?.userName} (${roleplayConfig.userTitle}). 🎥`}
                    highlight={false}
                    primary={false}
                    side={'left'}
                    user={user}
                  />

                  <Box
                    sx={{
                      position: 'fixed',
                      bottom: '40px',
                      right: '40px',
                      transition: 'all .3s ease-out'
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm="auto">
                        {!playEnabled && !scriptDone && (
                          <SpeedSelect
                            value={ttsSpeed}
                            onChange={handleOnChangeSpeedSelect}
                            sx={{ marginRight: '8px', width: 'auto' }}
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} sm="auto">
                        {!scriptDone && (
                          <THQPrimaryButton onClick={toggleSkipWatchDialog}>
                            <Skip
                              style={{
                                width: '14px',
                                height: '14px',
                                marginRight: '8px'
                              }}
                            />
                            Skip
                          </THQPrimaryButton>
                        )}
                      </Grid>
                      <Grid item xs={12} sm="auto">
                        {!playEnabled && !scriptDone && (
                          <THQPrimaryButton variant="main" onClick={!playEnabled ? playScript : pauseScript}>
                            {!playEnabled ? 'Start' : 'Stop'}
                          </THQPrimaryButton>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </RPChatContainer>
              </div>
            </Grid>
          </Grid>
        </RPContainer>
      )}
    </>
  );
};

export default WatchModeRunner;
