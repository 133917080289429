import React, { useCallback } from 'react';

import { Grid } from '@mui/material';

import ChromeLogo from '@/assets/images/chrome.png';
import FirefoxLogo from '@/assets/images/firefox.png';
import {
  BrowserCard,
  DownloadLinkTypography,
  HeadlineTypography,
  LogoContainerStyled,
  MainContentContainerStyled,
  SubtextTypography
} from '@/components/browserNotSupported/styles';
import ThemeLogo from '@/components/common/themeLogo/ThemeLogo';

const downloadChrome = 'https://www.google.com/chrome/';
const downloadFF = 'https://www.mozilla.org/en-US/firefox/new/';

const BrowserNotSupportedComponent: React.FC = () => {
  const handleOpenDownloadPage = useCallback((e) => {
    if (e.currentTarget.id === 'ff') {
      return window.open(downloadFF, '_blank');
    }
    return window.open(downloadChrome, '_blank');
  }, []);

  return (
    <>
      <LogoContainerStyled>
        <ThemeLogo width={'96px'} />
      </LogoContainerStyled>
      <MainContentContainerStyled>
        <Grid sx={{ justifyContent: 'center', margin: 'auto', maxWidth: '600px' }} container>
          <Grid sx={{ marginLeft: '16px', marginRight: '16px', marginBottom: '26px', maxWidth: '600px' }} item xs={12}>
            <Grid container>
              <Grid item>
                <HeadlineTypography>Supported browsers</HeadlineTypography>
                <SubtextTypography>
                  To get the most out of using the TrainHQ please login to the new experience with a supported browser:
                </SubtextTypography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item display={'flex'} justifyContent={'center'} xs={12} sm={'auto'}>
            <BrowserCard id="chrome" onClick={handleOpenDownloadPage}>
              <img height={100} width={'auto'} src={ChromeLogo} />
              <DownloadLinkTypography target="_blank" rel="noopener" underline="none" href={downloadChrome}>
                Download Chrome
              </DownloadLinkTypography>
            </BrowserCard>
          </Grid>
          <Grid item display={'flex'} justifyContent={'center'} xs={12} sm={'auto'}>
            <BrowserCard id="ff" onClick={handleOpenDownloadPage}>
              <img height={100} width={'auto'} src={FirefoxLogo} />
              <DownloadLinkTypography target="_blank" rel="noopener" underline="none" href={downloadFF}>
                Download Firefox
              </DownloadLinkTypography>
            </BrowserCard>
          </Grid>
        </Grid>
      </MainContentContainerStyled>
    </>
  );
};

export default BrowserNotSupportedComponent;
