import React, { useMemo, useState } from 'react';

import { THQTab, THQTabContainer, THQTabPanel } from '@trainhq/trainhq-client-core';

import { SelectedRPInsightsTabContext, useSelectedRPInsightsTabContext } from '@/components/insights/role-play/context';
import StrictDetailsTabPanel from '@/components/insights/role-play/details/StrictDetailsTabPanel';
import StrictOverviewTabPanel from '@/components/insights/role-play/overview/StrictOverviewTabPanel';
import StrictResponsesTabPanel from '@/components/insights/role-play/responses/StrictResponsesTabPanel';

const StrictRolePlay: React.FC = () => {
  const context = useSelectedRPInsightsTabContext();

  const [tabValue, setTabValue] = useState(0);

  const handleOnChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const contextValue = useMemo(
    () => ({
      ...context,
      tabValue
    }),
    [context, tabValue]
  );

  return (
    <SelectedRPInsightsTabContext.Provider value={contextValue}>
      <THQTabContainer scrollButtons={false} value={tabValue} variant="scrollable" onChange={handleOnChangeTab}>
        <THQTab tabIndex={0} label="Overview" />
        <THQTab tabIndex={1} label="Details" />
        <THQTab tabIndex={2} label="Responses" />
      </THQTabContainer>
      <THQTabPanel value={tabValue} index={0}>
        <StrictOverviewTabPanel />
      </THQTabPanel>
      <THQTabPanel value={tabValue} index={1}>
        <StrictDetailsTabPanel />
      </THQTabPanel>
      <THQTabPanel value={tabValue} index={2}>
        <StrictResponsesTabPanel />
      </THQTabPanel>
    </SelectedRPInsightsTabContext.Provider>
  );
};

export default StrictRolePlay;
