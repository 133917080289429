import React, { useMemo, useState } from 'react';

import {
  Box,
  Grid,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import { EmptyStateTemplate, PageRequest, selectMenuProps, THQSelect } from '@trainhq/trainhq-client-core';

import trafficCone from '@/assets/images/traffic_cone.png';
import CallsRow from '@/components/callIntelligence/callsRow/CallsRow';
import { useAuthenticatedUserContext } from '@/context/providers/AuthUserProvider';
import {
  useGetCallIntelligenceCallTypes,
  useGetCallIntelligencePage
} from '@/hooks/callIntelligence/useCallIntelligenceHooks';
import { NavArrowDownStyled, TableCellStyled, TableStyled } from '@/roleplay/naturalRolePlay/scorecardTabs/styles';

const CallsList: React.FC = () => {
  const [callTypes] = useGetCallIntelligenceCallTypes();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { activeCompany } = useAuthenticatedUserContext();

  const [selectedCallType, setSelectedCallType] = useState('-');

  const pageRequest: PageRequest = useMemo(() => ({ page: 0, size: 10 }), []);

  const {
    loadedItems: calls,
    search,
    changeSize,
    currentPageResult,
    nextPage,
    previousPage,
    loading
  } = useGetCallIntelligencePage(pageRequest);

  const handleChangePage = (e, newPage: number) => {
    if (newPage > currentPageResult.number && !loading) {
      nextPage();
    } else if (!loading) {
      previousPage();
    }
  };

  const handleChangeRowsPerPage = (event) => {
    changeSize(parseInt(event.target.value, 10));
  };

  const handleSetCallType = (e) => {
    setSelectedCallType(e.target.value);
    search(
      e.target.value === '-'
        ? []
        : [
            {
              key: 'callTypeLabel.uuid',
              value: e.target.value,
              operation: 'equals',
              relationType: 'AND'
            }
          ]
    );
  };

  const mappedTypes = useMemo(() => {
    return [
      { value: '-', label: 'All' },
      ...callTypes.map((item) => ({
        value: item.uuid,
        label: item.name
      }))
    ];
  }, [callTypes]);

  const handleRenderValue = (renderValue: unknown) => {
    if (renderValue === '-') {
      return 'All';
    }

    return callTypes.find((item) => item.uuid === renderValue).name;
  };

  return (
    <>
      {isMobile && (
        <EmptyStateTemplate
          title={'This screen size is not supported, yet.'}
          description={'Please switch to a larger device to use TrainHQ Reports.'}
          heroImage={trafficCone}
          heroImageMaxWidth={400}
        />
      )}
      {!isTablet && (
        <Grid item sx={{ marginBottom: '40px' }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography sx={{ fontWeight: 600 }} variant="h3">
                Calls
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      {!isMobile && (
        <Box>
          <Grid container sx={{ marginBottom: '12px' }}>
            <Grid item xs={true}>
              <Grid container spacing={'12px'}>
                <Grid item xs={true}>
                  <Typography sx={{ fontSize: '18px', fontWeight: 600 }} variant="h4">
                    All calls
                  </Typography>
                </Grid>
                <Grid item>
                  <THQSelect
                    label="Call type"
                    renderValue={handleRenderValue}
                    value={selectedCallType}
                    options={mappedTypes}
                    onChange={handleSetCallType}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* display grid fixes smaller windows issues with horizontal scroll */}
          <div style={{ display: 'grid', width: '100%' }}>
            <TableContainer>
              <TableStyled aria-label="table" noMargin noBorder>
                <TableHead>
                  <TableRow>
                    <TableCellStyled>Call type</TableCellStyled>
                    <TableCellStyled>Date</TableCellStyled>
                    <TableCellStyled>Company</TableCellStyled>
                    <TableCellStyled>Success rate</TableCellStyled>
                    {activeCompany?.companyConfiguration?.deleteCallIntelligenceSourceData && (
                      <>
                        <TableCellStyled>External Call Url</TableCellStyled>
                        <TableCellStyled>Call UUID</TableCellStyled>
                      </>
                    )}
                    <TableCellStyled></TableCellStyled>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {calls?.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={11}>
                        <Typography fontSize={14}>No results found.</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  {(calls || []).map((item) => (
                    <CallsRow key={item.uuid} callEntity={item} />
                  ))}
                </TableBody>
              </TableStyled>
            </TableContainer>
            <TableContainer>
              <TableStyled noBorder sx={{ marginTop: '0', borderTopLeftRadius: '0', borderTopRightRadius: '0' }}>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      ActionsComponent={TablePaginationActions}
                      colSpan={7}
                      count={currentPageResult?.totalElements || 0}
                      labelRowsPerPage="Learners per page"
                      page={currentPageResult?.number || 0}
                      rowsPerPage={currentPageResult?.pageable?.pageSize || 10}
                      rowsPerPageOptions={[2, 10, 20, 50]}
                      SelectProps={{
                        IconComponent: NavArrowDownStyled,
                        MenuProps: selectMenuProps
                      }}
                      sx={{ borderBottom: 'none' }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableFooter>
              </TableStyled>
            </TableContainer>
          </div>
        </Box>
      )}
    </>
  );
};

export default CallsList;
