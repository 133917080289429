import React, { useCallback } from 'react';

import { Grid, Typography } from '@mui/material';
import { getFormattedDateString, TimeIcon } from '@trainhq/trainhq-client-core';

import AstroOnPlanet from '@/assets/images/astro-on-planet.png';
import BorderLinearProgress from '@/components/common/progress/BorderLinearProgress';
import { ProgressTypographyStyled } from '@/components/common/progress/styles';
import BurgerButton from '@/components/journey/common/BurgerButton';
import {
  ContainerGridStyled,
  IconWrapperStyled,
  ImageBoxStyled,
  ImageStyled
} from '@/components/journey/detailsPanel/styles';
import JourneyDetailsDrawer from '@/components/journey/drawer/JourneyDetailsDrawer';
import { useJourneyContext, useJourneySetters } from '@/hooks/journey/useJourneyContext';

const JourneyDetailsPanel: React.FC = () => {
  const { journey, openDetailsDrawer } = useJourneyContext();
  const { setOpenDetailsDrawer } = useJourneySetters();

  const onBurgerClick = useCallback(() => {
    setOpenDetailsDrawer((prevState) => !prevState);
  }, [setOpenDetailsDrawer]);

  return (
    <JourneyDetailsDrawer open={openDetailsDrawer}>
      <ContainerGridStyled container direction="column">
        <Grid item sx={{ marginBottom: '40px' }}>
          <BurgerButton onClick={onBurgerClick} />
        </Grid>
        <Grid item sx={{ marginBottom: '16px' }}>
          <Typography fontSize={18} fontWeight={700}>
            {journey?.name}
          </Typography>
        </Grid>
        <Grid item sx={{ marginBottom: '16px' }}>
          <BorderLinearProgress sx={{ borderRadius: '2px' }} variant="determinate" value={journey?.percentage} />
        </Grid>
        <Grid item sx={{ marginBottom: '28px' }}>
          <ProgressTypographyStyled>{journey?.percentage}% complete</ProgressTypographyStyled>
        </Grid>
        <Grid alignSelf="center" item sx={{ marginBottom: '48px', position: 'relative' }}>
          <ImageStyled alt="journey-details-image" src={AstroOnPlanet} />
          <ImageBoxStyled />
        </Grid>
        <Grid item sx={{ marginBottom: '24px' }}>
          <Grid alignItems="center" container columnSpacing={1}>
            <Grid item xs="auto">
              <IconWrapperStyled>
                <TimeIcon style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
              </IconWrapperStyled>
            </Grid>
            <Grid item xs>
              <Typography fontSize={14}>
                Due{' '}
                <Typography component="span" fontSize={14} fontWeight={700}>
                  {getFormattedDateString({
                    dueDate: journey?.dueDate,
                    withAdjective: true
                  })}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sx={{ marginBottom: '24px' }}>
          <Grid alignItems="center" container columnSpacing={1}>
            <Grid item xs="auto">
              <IconWrapperStyled>
                <TimeIcon style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
              </IconWrapperStyled>
            </Grid>
            <Grid item xs>
              <Typography fontSize={14}>
                You are waiting for{' '}
                <Typography component="span" fontSize={14} fontWeight={700}>
                  {journey?.pendingFeedbackCount || 0}
                </Typography>{' '}
                feedbacks
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid alignItems="center" container columnSpacing={1}>
            <Grid item xs="auto">
              <IconWrapperStyled>
                <TimeIcon style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
              </IconWrapperStyled>
            </Grid>
            <Grid item xs>
              <Typography fontSize={14}>
                You have completed{' '}
                <Typography component="span" fontSize={14} fontWeight={700}>
                  {journey?.numberOfCompletedSteps}/{journey?.numberOfSteps}
                </Typography>{' '}
                steps.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </ContainerGridStyled>
    </JourneyDetailsDrawer>
  );
};

export default JourneyDetailsPanel;
