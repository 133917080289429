import React from 'react';

import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { getFormattedDateString, Journey, ProgressStatus } from '@trainhq/trainhq-client-core';
import { useNavigate } from 'react-router';

import {
  DescriptionStyled,
  THQContentBuilderCardStyled,
  THQSmallHeadlineStyled
} from '@/components/common/contentBuilderList/styles';
import { DueDateTypography } from '@/components/common/styles';
import { JOURNEY_STRING } from '@/constants/router';
import { getChipInfo } from '@/utils/courseUtils';

interface JourneyListItemProps {
  journey: Journey;
}

// TODO: use context to prevent prop drilling
const JourneyListItem: React.FC<JourneyListItemProps> = ({ journey }) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const handleOnJourneyClick = () => {
    navigate(`${JOURNEY_STRING}/${journey?.uuid}`);
  };

  return (
    <Grid item lg={4} md={6} sm={6} xs={12}>
      {
        <THQContentBuilderCardStyled
          asFraction
          learnerLayout
          total={journey?.numberOfSteps}
          passed={journey?.numberOfCompletedSteps}
          description={journey?.description ? <DescriptionStyled>{journey?.description}</DescriptionStyled> : undefined}
          descriptionPlaceholder={<DescriptionStyled asPlaceholder>No journey description</DescriptionStyled>}
          subtitle={<THQSmallHeadlineStyled onClick={handleOnJourneyClick}>JOURNEY</THQSmallHeadlineStyled>}
          footnote={
            <>
              {journey?.progressStatus !== ProgressStatus.COMPLETED && (
                <DueDateTypography overdue={journey?.dueDate - new Date().valueOf() < 0}>
                  {journey?.dueDate && <>Due {getFormattedDateString({ dueDate: journey?.dueDate })}</>}
                </DueDateTypography>
              )}
            </>
          }
          progressBar={(journey?.numberOfCompletedSteps / journey?.numberOfSteps) * 100}
          chipInfo={getChipInfo(journey.progressStatus, theme)}
          button={{
            variant:
              journey?.progressStatus === ProgressStatus.NOT_STARTED ||
              (journey?.progressStatus === 'DUE' && journey?.numberOfCompletedSteps === 0)
                ? 'main'
                : 'standard',
            text:
              journey?.progressStatus === ProgressStatus.NOT_STARTED ||
              (journey?.progressStatus === ProgressStatus.DUE && journey?.numberOfCompletedSteps === 0)
                ? 'Start'
                : journey?.progressStatus === ProgressStatus.COMPLETED
                ? 'View'
                : 'Continue',
            action: handleOnJourneyClick
          }}
          entity={journey}
        />
      }
    </Grid>
  );
};

export default JourneyListItem;
