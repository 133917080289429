import React, { useCallback, useEffect, useState } from 'react';

import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { EmptyStateTemplate, LearnerFeedbackThread } from '@trainhq/trainhq-client-core';

import nofeedback from '@/assets/images/no_feedback.png';
import { FeedbackCard } from '@/components/feedback/feedbackCard/FeedbackCard';
import { CourseListRootStyled, SubsectionHeadline } from '@/components/home/styles';
import { useFeedbackService, useGetAllPendingThreads } from '@/hooks/feedback/useCourseService';

const Feedback: React.FC = () => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const feedbackService = useFeedbackService();

  const [pendingThreads] = useGetAllPendingThreads();
  const [pendingCount, setPendingCount] = useState<number>();

  useEffect(() => {
    const sub = feedbackService.observeFeedbackSummary().subscribe({
      next: (summary) => {
        setPendingCount(summary?.pendingItems ?? 0);
      }
    });
    feedbackService.refreshFeedbackItemsCountSummary();
    return () => sub.unsubscribe();
  }, [feedbackService]);

  const titleRender = useCallback(
    (thread: LearnerFeedbackThread) => (
      <Typography>
        {thread.admin.firstName} {thread.admin.lastName} <span style={{ fontWeight: 400 }}>gave you feedback for</span>{' '}
        {thread.quizContent}
      </Typography>
    ),
    []
  );

  return (
    <CourseListRootStyled>
      <Grid xs={12} sx={{ marginBottom: '40px' }} item>
        <Grid container rowSpacing={2}>
          {!isTablet && (
            <Grid sx={{ marginBottom: '56px' }} xs={12} item>
              <SubsectionHeadline sx={{ fontSize: '1.5rem', fontWeight: '600' }}>
                Latest feedback {pendingCount > 0 ? `(${pendingCount})` : ''}
              </SubsectionHeadline>
            </Grid>
          )}
          {(!pendingThreads || pendingThreads?.length === 0) && (
            <EmptyStateTemplate
              title="No feedback items yet"
              description="Once the feedbacks arrive, you will be able to see them here."
              heroImage={nofeedback}
              heroImageMaxWidth={240}
            />
          )}
          {pendingThreads?.length > 0 &&
            pendingThreads?.map((thread) => (
              <Grid xs={12} sm={10} item key={thread.uuid}>
                <FeedbackCard
                  feedbackObject={thread}
                  avatarColorGenString={`${thread.admin.firstName} ${thread.admin.lastName}`}
                  avatarSrc={thread.admin.profilePicUrl}
                  avatarText={`${thread.admin.firstName[0]}${thread.admin.lastName[0]}`}
                  courseTitle={thread.courseName}
                  title={titleRender(thread)}
                  feedback={thread?.adminFeedback?.message || ''}
                  description={
                    thread?.adminFeedback?.createdAt
                      ? new Intl.DateTimeFormat('en-US', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric'
                        }).format(new Date(thread.adminFeedback.createdAt))
                      : ''
                  }
                />
              </Grid>
            ))}
        </Grid>
      </Grid>
    </CourseListRootStyled>
  );
};

export default Feedback;
