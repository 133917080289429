import { ProgressStatus } from '@trainhq/trainhq-client-core';

export const getButtonText = (courseProgress: ProgressStatus, entityName?: string) => {
  return courseProgress === ProgressStatus.FEEDBACK_REQUIRED
    ? 'Respond'
    : courseProgress === ProgressStatus.COMPLETED
    ? entityName === 'role-play'
      ? 'Practice'
      : 'View'
    : courseProgress === ProgressStatus.IN_PROGRESS || courseProgress === ProgressStatus.PENDING_RESPONSE
    ? 'Continue'
    : entityName
    ? `Start ${entityName}`
    : 'Start course';
};
