import React, { createContext, useCallback, useContext, useState } from 'react';

import { Container, Dialog, Grid, Typography } from '@mui/material';
import { THQDialogContent, THQPrimaryButton } from '@trainhq/trainhq-client-core';
import { useSearchParams } from 'react-router-dom';

import Confetti from '@/assets/images/confetti.png';

type ConfettiOverlayContext = (action: () => void) => void;
const context = createContext<ConfettiOverlayContext>(null);
export const useConfettiOverlay = () => useContext(context);

interface ConfettiOverlayProps {
  children?: React.ReactNode;
}

const ConfettiOverlay: React.FC<ConfettiOverlayProps> = ({ children }) => {
  const [searchParams] = useSearchParams();
  const previewModeOn = searchParams.get('preview') == '1';

  const [open, setOpen] = useState<boolean>(false);
  const [actionCallback, setActionCallback] = useState<() => void>();

  const contextValue = useCallback<ConfettiOverlayContext>((callback) => {
    setActionCallback(() => callback);
    setOpen(true);
  }, []);

  return (
    <context.Provider value={contextValue}>
      {children}
      <Dialog fullScreen open={open}>
        <THQDialogContent>
          <Grid alignItems="center" container sx={{ height: '100%' }} justifyContent="center">
            <Grid item>
              <Container maxWidth="sm">
                <Grid container direction="column">
                  <Grid alignSelf="center" item>
                    <img alt="confetti" src={Confetti} width={120} />
                  </Grid>
                  <Grid item>
                    <Typography align="center" fontSize={16} fontWeight={700}>
                      {previewModeOn ? 'You’ve completed the course preview' : 'Woo-hoo! You did it!'}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography align="center" fontSize={14}>
                      {previewModeOn
                        ? 'When learners complete this course, any open ended quiz responses will be routed to the assigned reviewer.'
                        : 'That’s it. We will notify you once the coach reviews your responses. Until then, sit back and relax. Or complete some additional course.'}
                    </Typography>
                  </Grid>
                  <Grid item sx={{ marginTop: '28px' }}>
                    <Grid container justifyContent="center">
                      <Grid item>
                        <THQPrimaryButton variant="main" onClick={actionCallback}>
                          Sounds good!
                        </THQPrimaryButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            </Grid>
          </Grid>
        </THQDialogContent>
      </Dialog>
    </context.Provider>
  );
};

export default ConfettiOverlay;
