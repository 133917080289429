import React from 'react';

import { EmptyStateTemplate, MainLayout, THQMainPageContainer } from '@trainhq/trainhq-client-core';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Logo } from '@/assets/icons/logo.svg';
import notFoundHeroImg from '@/assets/images/not_found_hero.png';
import ThemeLogo from '@/components/common/themeLogo/ThemeLogo';
import { HOME } from '@/constants/router';
import { LogoContainerStyled } from '@/pages/notFound/styles';

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();

  const handleRedirectToDashboard = () => {
    navigate(HOME);
  };
  return (
    <MainLayout>
      <THQMainPageContainer>
        <LogoContainerStyled>
          <ThemeLogo width={'96px'} />
        </LogoContainerStyled>
        <EmptyStateTemplate
          title={'We can’t find what you’re looking for'}
          description={'It looks like the resource you’re looking for doesn’t exist.'}
          buttonAction={handleRedirectToDashboard}
          buttonText={'Go to dashboard'}
          heroImage={notFoundHeroImg}
          heroImageMaxWidth={240}
        />
      </THQMainPageContainer>
    </MainLayout>
  );
};

export default NotFoundPage;
