import React, { useEffect } from 'react';

import { ConfirmPasswordComponent } from '@trainhq/trainhq-client-core';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { HOME } from '@/constants/router';
import { useLoadingOverlayContext } from '@/hooks/loadingOverlay/loadingOverlayContext';
import { useGetPasswordPolicy, useUserManagementService } from '@/hooks/userManagement/useUserManagementService';

const ConfirmPasswordPage: React.FC = () => {
  const loadingOverlayContext = useLoadingOverlayContext();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const userManagementService = useUserManagementService();
  const passPolicy = useGetPasswordPolicy(token);

  useEffect(() => {
    loadingOverlayContext.setLoading(true);
    loadingOverlayContext.setLoadingText('Validating token...');
    userManagementService.validateToken(token).subscribe({
      next: () => {
        loadingOverlayContext.setLoading(false);
      },
      error: (err) => {
        console.log(err);
      }
    });
  }, [loadingOverlayContext, token, userManagementService]);

  const handleSubmit = ({ password }) => {
    userManagementService
      .resetPassword({
        token: token,
        newPassword: password
      })
      .subscribe(() => {
        navigate(HOME);
      });
  };
  const handleGoBack = () => {
    navigate(HOME);
  };

  return <ConfirmPasswordComponent passwordPolicy={passPolicy} onGoBack={handleGoBack} onSubmit={handleSubmit} />;
};

export default ConfirmPasswordPage;
