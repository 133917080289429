import React, { useEffect, useState } from 'react';

import { LoginSetUpAccountComponent, SetUpAccountForm } from '@trainhq/trainhq-client-core';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { catchError, EMPTY, finalize } from 'rxjs';
import { skip, tap } from 'rxjs/operators';

import { HOME } from '@/constants/router';
import { useLoadingOverlayContext } from '@/hooks/loadingOverlay/loadingOverlayContext';
import { useGetPasswordPolicy, useUserManagementService } from '@/hooks/userManagement/useUserManagementService';
import { TokenExpiredHeadline, TokenInvalidOverlay } from '@/pages/auth/styles';

const RegisterPage: React.FC = () => {
  const userManagementService = useUserManagementService();
  const loadingOverlayContext = useLoadingOverlayContext();
  const [tokenValid, setTokenValid] = useState<boolean>(null);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const firstName = searchParams.get('firstName');
  const navigate = useNavigate();
  const passPolicy = useGetPasswordPolicy(token);

  useEffect(() => {
    loadingOverlayContext.setLoading(true);
    loadingOverlayContext.setLoadingText('Validating token...');
    userManagementService.validateToken(token).subscribe({
      next: (res) => {
        loadingOverlayContext.setLoading(false);
        setTokenValid(res?.valid ?? false);
      },
      error: (err) => {
        console.log(err);
      }
    });
  }, []);

  const handleSubmit = ({ firstName, lastName, password }) => {
    return userManagementService
      .verify({
        password: password,
        token: token,
        user: {
          firstName: firstName,
          lastName: lastName
        }
      })
      .pipe(
        tap({
          next: () => {
            navigate(HOME);
          }
        })
      );
  };

  return (
    <>
      {tokenValid === false && (
        <TokenInvalidOverlay>
          <TokenExpiredHeadline>Link is invalid or has expired, please ask for another link</TokenExpiredHeadline>
        </TokenInvalidOverlay>
      )}
      {tokenValid && (
        <LoginSetUpAccountComponent
          isRegister
          form={<SetUpAccountForm passwordPolicy={passPolicy} onSubmit={handleSubmit} />}
          title={`Hi, ${firstName}. Let’s set up your TrainHQ account.`}
        />
      )}
    </>
  );
};

export default RegisterPage;
