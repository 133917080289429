import React from 'react';

import { CircularProgress, Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';

import AuthUserProvider from '@/context/providers/AuthUserProvider';
import { useCheckSSOAuthentication } from '@/context/userManagement';

const PrivateRoutes: React.FC = () => {
  const authData = useCheckSSOAuthentication();

  if (authData.authenticated == null || !authData.authenticated || !authData.user)
    return (
      <Grid container direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }}>
        <CircularProgress />
      </Grid>
    );
  return authData.authenticated ? (
    <AuthUserProvider user={authData.user}>
      <Outlet />
    </AuthUserProvider>
  ) : (
    <></>
  );
};

export default PrivateRoutes;
