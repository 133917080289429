import React from 'react';

import { Grid, Typography } from '@mui/material';
import { ProgressStatus, RoleplayBuilder } from '@trainhq/trainhq-client-core';
import { useNavigate } from 'react-router';

import { ActiveTab } from '@/components/common/contentBuilderList/ContentBuilderList';
import { DescriptionStyled, THQContentBuilderCardStyled } from '@/components/common/contentBuilderList/styles';
import { parseDescriptionAddLinks } from '@/components/common/utils/parseTextIntoLink';

interface RoleplayListItemProps {
  roleplay: RoleplayBuilder;
}

// TODO: use context to prevent prop drilling
const RoleplayListItem: React.FC<RoleplayListItemProps> = ({ roleplay }) => {
  const navigate = useNavigate();

  const handleOnRPClick = (rp: RoleplayBuilder) => {
    const tabToOpen = rp.progressStatus === ProgressStatus.COMPLETED ? ActiveTab.COMPLETED : ActiveTab.YOUR_CONTENT;
    navigate(`/role-play?roleplay=${roleplay?.uuid}`, {
      state: tabToOpen
    });
  };

  const parseDescription = (desc) => {
    return parseDescriptionAddLinks(desc);
  };

  return (
    <Grid item lg={4} md={6} sm={6} xs={12}>
      {
        <THQContentBuilderCardStyled
          entity={roleplay}
          learnerLayout
          asFraction
          progressTooltip="Successfully completed without error"
          total={roleplay.passRequirement === 0 ? 1 : roleplay.passRequirement}
          passed={
            roleplay.progressStatus === ProgressStatus.COMPLETED
              ? roleplay.passRequirement
              : roleplay.finishedInARow > roleplay.passRequirement
              ? roleplay.passRequirement
              : roleplay.finishedInARow
          }
          description={
            roleplay?.description ? (
              <DescriptionStyled>{parseDescription(roleplay?.description)}</DescriptionStyled>
            ) : undefined
          }
          descriptionPlaceholder={<DescriptionStyled asPlaceholder>No description</DescriptionStyled>}
          progressBar={
            roleplay.rolePlayType === 'STRICT'
              ? roleplay.progressStatus === ProgressStatus.COMPLETED
                ? 100
                : roleplay.finishedInARow > roleplay.passRequirement
                ? 100
                : (roleplay.finishedInARow / (roleplay.passRequirement === 0 ? 1 : roleplay.passRequirement)) * 100
              : undefined
          }
          button={{
            variant: roleplay.progressStatus === ProgressStatus.NOT_STARTED ? 'main' : 'standard',
            text:
              roleplay?.progressStatus === ProgressStatus.NOT_STARTED
                ? 'Start'
                : roleplay?.progressStatus === ProgressStatus.COMPLETED
                ? 'Practice'
                : 'Continue',
            action: handleOnRPClick
          }}
        />
      }
    </Grid>
  );
};

export default RoleplayListItem;
