import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { MediaFileUploadService, useMediaFileUploaderService } from '@trainhq/trainhq-client-core';

import { renderMediaContent } from '@/components/course/block/common/quizmediaRender/QuizMediaRender';
import { ConfirmAnswerButton, ContentContainerStyled } from '@/components/course/block/common/styles';
import AnswerTextComponent from '@/components/course/block/multipleChoice/answerChoice/AnswerText';
import BlockNav from '@/components/course/block/navigation/BlockNav';
import { useCourseContext } from '@/components/course/courseDetails/context';
import { useFeedbackService } from '@/hooks/feedback/useCourseService';
import { LearnerBlock } from '@/models/learnerModels';

interface MultipleChoiceComponentProps {
  block: LearnerBlock;
  journeyUuid?: string;
}

const MultipleChoice: React.FC<MultipleChoiceComponentProps> = ({ block, journeyUuid }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { course, previewModeOn, setBlockDetails } = useCourseContext();
  const feedbackService = useRef(useFeedbackService()).current;
  const mediaFileUploaderServiceRef = useRef<MediaFileUploadService>();
  mediaFileUploaderServiceRef.current = useMediaFileUploaderService();
  const [committingAnswer, setCommittingAnswer] = useState(false);
  const [confirmingResponse, setConfirmingResponse] = useState<string>('');
  const questionMedia = useMemo(() => block?.learnerMultipleChoiceQuiz?.question?.itemMediaContent, []); // TODO: fix deps

  const [correctAnswerFound, setCorrectAnswerFound] = useState(false);
  const [tryAgain, setTryAgain] = useState(false);

  const [selectedAnswer, setSelectedAnswer] = useState('');

  useEffect(() => {
    setTryAgain(false);
    setSelectedAnswer('');
  }, [block?.uuid]);

  useEffect(() => {
    const correctAnswerSelected = block?.learnerMultipleChoiceQuiz?.answers?.some(
      (item) => item?.answered && item?.correct
    );
    setCorrectAnswerFound(correctAnswerSelected);
  }, [block?.learnerMultipleChoiceQuiz?.answers]);

  const handleTryAgain = () => {
    setTryAgain(false);
    setSelectedAnswer('');
  };

  const handleConfirmAnswer = () => {
    if (previewModeOn) {
      const selectedAnswerObject = block?.learnerMultipleChoiceQuiz?.answers?.find((a) => a.uuid === selectedAnswer);
      setBlockDetails((b) => ({
        ...b,
        learnerMultipleChoiceQuiz: {
          ...b.learnerMultipleChoiceQuiz,
          answers: b.learnerMultipleChoiceQuiz?.answers?.map((a) => {
            if (a.uuid === selectedAnswerObject.uuid) {
              return { ...selectedAnswerObject, answered: true };
            }
            return a;
          })
        }
      }));
      setTryAgain(true);
    } else {
      setConfirmingResponse('Confirming...');
      setCommittingAnswer(true);
      feedbackService
        .addMultiChoiceAnswer({ blockUuid: block.uuid, answerUuid: selectedAnswer, journeyUuid: journeyUuid })
        .subscribe({
          next: (result) => {
            setConfirmingResponse('');
            setCommittingAnswer(false);
            if (!result.correct) {
              setTryAgain(true);
            }
            setBlockDetails((b) => ({
              ...b,
              learnerMultipleChoiceQuiz: {
                ...b.learnerMultipleChoiceQuiz,
                answers: b.learnerMultipleChoiceQuiz?.answers?.map((a) => {
                  if (a.uuid === result.uuid) {
                    return result;
                  }
                  return a;
                })
              }
            }));
          }
        });
    }
  };

  return (
    <>
      {!!course && !!block && (
        <ContentContainerStyled maxWidth="lg">
          <Grid container justifyContent={'center'}>
            <Grid
              xs={12}
              sx={{ justifyContent: 'center', padding: 3, ...(questionMedia && { paddingBottom: '0' }) }}
              item
            >
              <Typography align="center" fontSize={24} fontWeight={600} sx={{ paddingBottom: '32px' }}>
                {block?.learnerMultipleChoiceQuiz?.question &&
                  block?.learnerMultipleChoiceQuiz?.question.itemTextContent}
              </Typography>
            </Grid>
            <Grid
              xs={12}
              sm={10}
              md={6}
              sx={{ justifyContent: 'center', paddingBottom: '32px' }}
              display={'flex'}
              justifyContent={'center'}
              alignItems="flex-start"
              item
            >
              {block?.learnerMultipleChoiceQuiz?.question?.itemMediaContent &&
                renderMediaContent(block?.learnerMultipleChoiceQuiz?.question?.itemMediaContent)}
            </Grid>

            <Grid item xs={isMobile ? 12 : 8}>
              <Grid
                container
                direction={isMobile ? 'column' : 'row'}
                flexWrap={isMobile ? 'nowrap' : 'wrap'}
                spacing={'16px'}
              >
                {block?.learnerMultipleChoiceQuiz?.answers?.map((ans) => (
                  <Grid item key={ans.uuid} xs={isMobile ? 12 : 6}>
                    <AnswerTextComponent
                      selectable={!tryAgain && !correctAnswerFound}
                      committingAnswer={committingAnswer}
                      onClick={setSelectedAnswer}
                      isSelected={selectedAnswer === ans.uuid}
                      answerOption={ans}
                    />
                  </Grid>
                ))}
              </Grid>
              {/* minHeight: 180 is a hack that makes navigation work normally on small screens. Otherwise, when there's no confirm button, it looks bad */}
              <Grid container justifyContent="center" sx={{ minHeight: '180px' }}>
                <Grid item sx={{ marginTop: '12px' }}>
                  {!correctAnswerFound && (
                    <ConfirmAnswerButton
                      disabled={!selectedAnswer}
                      inProgress={confirmingResponse}
                      variant="fancy"
                      onClick={tryAgain ? handleTryAgain : handleConfirmAnswer}
                    >
                      {tryAgain ? 'Try Again' : 'Confirm Response'}
                    </ConfirmAnswerButton>
                  )}
                </Grid>
              </Grid>
              <BlockNav disabled={!previewModeOn && !correctAnswerFound} />
            </Grid>
          </Grid>
        </ContentContainerStyled>
      )}
    </>
  );
};

export default MultipleChoice;
