import React, { useCallback, useState } from 'react';

import { CircularProgress, Grid, Typography } from '@mui/material';
import { ChallengeType, getSuccessRateFrom, THQSelect } from '@trainhq/trainhq-client-core';
import { useParams } from 'react-router';

import { useSelectedRPInsightsTabContext } from '@/components/insights/role-play/context';
import NaturalDetailsCard from '@/components/insights/role-play/details/naturalDetailsCard/NaturalDetailsCard';
import { useAuthenticatedUserContext } from '@/context/providers/AuthUserProvider';
import { useGetNaturalDetailsData, useGetNRPSections } from '@/hooks/roleplay/insights/useRolePlayAnalyticsService';

const initialTypeOptions = [
  { label: 'All', value: '-' },
  { label: 'Goal', value: ChallengeType.GOAL },
  { label: 'Objection', value: ChallengeType.OBJECTION }
];

const initialSuccessRateOptions = [
  { label: 'All', value: '-' },
  { label: '59% or below', value: '<59' },
  { label: '60%-69%', value: '<69' },
  { label: '70%-79%', value: '<79' },
  { label: '80%-89%', value: '<89' },
  { label: '90% or more', value: '>90' }
];

const NaturalDetailsTabPanel: React.FC = () => {
  const { rolePlayUuid } = useParams<{ rolePlayUuid: string }>();
  const { tabValue, selectedTypeOption } = useSelectedRPInsightsTabContext();
  const user = useAuthenticatedUserContext();

  const [typeFilter, setTypeFilter] = useState<ChallengeType | '-'>('-');
  const [sectionFilter, setSectionFilter] = useState<string>('-');
  const [successRateFilter, setSuccessRateFilter] = useState<'<59' | '<69' | '<79' | '<89' | '>90' | '-'>('-');

  const sectionOptions = useGetNRPSections(rolePlayUuid);
  const mappedOptions = sectionOptions?.map((section) => ({ label: section.section, value: section.uuid }));

  const skip = tabValue !== 1;
  const successRates = successRateFilter === '-' ? null : getSuccessRateFrom(successRateFilter);

  const { loading, naturalDetailsData } = useGetNaturalDetailsData(
    rolePlayUuid,
    selectedTypeOption,
    skip,
    typeFilter === '-' ? null : typeFilter,
    sectionFilter === '-' ? null : sectionFilter,
    successRates?.successRateFrom,
    successRates?.successRateTo
  );

  const handleOnChangeTypeFilter = useCallback((e) => {
    setTypeFilter(e.target.value);
  }, []);

  const handleOnChangeSectionFilter = useCallback((e) => {
    setSectionFilter(e.target.value);
  }, []);

  const handleOnChangeSuccessRateFilter = useCallback((e) => {
    setSuccessRateFilter(e.target.value);
  }, []);

  const dataExists = naturalDetailsData?.user?.length > 0;
  return skip ? null : (
    <>
      <Grid container spacing={1} sx={{ marginBottom: '16px' }}>
        <Grid item>
          <THQSelect label="Type" options={initialTypeOptions} value={typeFilter} onChange={handleOnChangeTypeFilter} />
        </Grid>
        <Grid item>
          <THQSelect
            label="Section"
            options={mappedOptions}
            value={sectionFilter}
            onChange={handleOnChangeSectionFilter}
          />
        </Grid>
        <Grid item>
          <THQSelect
            label="Success rate"
            options={initialSuccessRateOptions}
            value={successRateFilter}
            onChange={handleOnChangeSuccessRateFilter}
          />
        </Grid>
      </Grid>
      {loading ? (
        <Grid sx={{ marginTop: '16px' }}>
          <CircularProgress />
        </Grid>
      ) : (
        <>
          {dataExists ? (
            <Grid container spacing={2}>
              {naturalDetailsData?.user?.map((pair, i) => (
                <Grid item key={`${pair.uuid}${i}`} md={6} xs={12}>
                  <NaturalDetailsCard
                    compareUserAttempts={naturalDetailsData?.comparingUser?.[i]?.attempts}
                    compareUserErrorCount={naturalDetailsData?.comparingUser?.[i]?.errorCount}
                    globalAttempts={naturalDetailsData?.global?.[i]?.attempts}
                    globalErrorCount={naturalDetailsData?.global?.[i]?.errorCount}
                    isComparing={false}
                    sectionName={pair.sectionName}
                    selectedLearner={user}
                    tabValue={tabValue}
                    title={pair.challengeName}
                    type={pair.challengeType}
                    userAttempts={naturalDetailsData?.user?.[i]?.attempts}
                    userErrorCount={naturalDetailsData?.user?.[i]?.errorCount}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography>No data available yet</Typography>
          )}
        </>
      )}
    </>
  );
};

export default NaturalDetailsTabPanel;
