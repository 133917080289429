import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { Grid, Typography } from '@mui/material';
import {
  THQDialog,
  THQDialogActionButton,
  THQDialogActions,
  THQDialogContent,
  THQDialogTitle,
  useTHQSnackbar
} from '@trainhq/trainhq-client-core';
import { useNavigate } from 'react-router';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Subscription, timer } from 'rxjs';

import { ActiveTab } from '@/components/common/contentBuilderList/ContentBuilderList';
import { COURSES } from '@/constants/router';

type CourseInactivityDialogContext = (inactivityPeriodInMinutes: number) => void;

const context = createContext<CourseInactivityDialogContext>(null);
export const useCourseInactivityDialogContext = () => useContext(context);

interface CourseInactivityDialogProps {
  children?: React.ReactNode;
}

const CourseInactivityDialog: React.FC<CourseInactivityDialogProps> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const tabToReturn = location.state === ActiveTab.COMPLETED ? '?activeTab=completed' : '?activeTab=your-course';
  const [searchParams] = useSearchParams();
  const returnTo = searchParams.get('returnTo');
  const snackbar = useTHQSnackbar();

  const [open, setOpen] = useState<boolean>(false);
  const [inactivityPeriodInMinutes, setInactivityPeriodInMinutes] = useState<number>();
  const [timeTillExpire, setTimeTillExpire] = useState<number>(60);

  const handleOnClose = useCallback(() => {
    setOpen(false);
    setTimeTillExpire(60);
  }, []);

  useEffect(() => {
    const subs = new Subscription();
    if (open) {
      subs.add(
        timer(0, 1000).subscribe(() => {
          setTimeTillExpire((prevState) => prevState - 1);
        })
      );
    }
    return () => {
      subs.unsubscribe();
    };
  }, [open]);

  useEffect(() => {
    if (timeTillExpire === 0) {
      handleOnClose();
      if (returnTo) {
        navigate(returnTo);
      } else {
        navigate(`${COURSES}${tabToReturn}`);
      }
      snackbar('You were timed out.');
    }
  }, [handleOnClose, navigate, returnTo, snackbar, tabToReturn, timeTillExpire]);

  const contextValue = useCallback((inactivityPeriodInMinutes: number) => {
    setOpen(true);
    setInactivityPeriodInMinutes(inactivityPeriodInMinutes);
  }, []);

  return (
    <context.Provider value={contextValue}>
      {children}
      <THQDialog fullWidth open={open} onClose={handleOnClose}>
        <THQDialogTitle>Are you still there?</THQDialogTitle>
        <THQDialogContent>
          <Typography>
            Just checking if you are still learning hard, you have been inactive for {inactivityPeriodInMinutes}{' '}
            {inactivityPeriodInMinutes === 1 ? 'minute' : 'minutes'}. If you are here, just confirm so we know to keep
            you in this course.
          </Typography>
          <Typography>
            Your session will expire in{' '}
            <Typography component="span" fontWeight={700} sx={{ marginTop: '16px' }}>
              {timeTillExpire} {timeTillExpire === 1 ? 'second' : 'seconds'}
            </Typography>
            .
          </Typography>
        </THQDialogContent>
        <THQDialogActions>
          <Grid container justifyContent="end" spacing={1}>
            <Grid item sm="auto" xs={12}>
              <THQDialogActionButton variant="main" onClick={handleOnClose}>
                Yes, I am here
              </THQDialogActionButton>
            </Grid>
          </Grid>
        </THQDialogActions>
      </THQDialog>
    </context.Provider>
  );
};

export default CourseInactivityDialog;
