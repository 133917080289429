import React from 'react';

import { Typography, Grid } from '@mui/material';
import {
  THQDialog,
  THQDialogTitle,
  THQDialogContent,
  THQDialogActions,
  THQDialogActionButton
} from '@trainhq/trainhq-client-core';

export interface ErrorInSpeechSynthesisDialogProps {
  open: boolean;
  handleOnClose?: () => void;
  handleOnConfirmSecondary: () => void;
  handleOnConfirm?: () => void;
}

const ErrorInSpeechSynthesisDialog = ({ open, handleOnClose, handleOnConfirm, handleOnConfirmSecondary }) => {
  return (
    <THQDialog fullWidth open={open} onClose={handleOnClose}>
      <THQDialogTitle>Error in speech synthesis</THQDialogTitle>
      <THQDialogContent>
        <Typography>
          There was an error in speech synthesis.
          <br />
          Would you like to start watching the role-play from the top, or skip and move to practicing?
        </Typography>
      </THQDialogContent>
      <THQDialogActions sx={{ borderTop: 'none' }}>
        <Grid container justifyContent="flex-end">
          <Grid item sm="auto" justifyContent="flex-end" xs={12}>
            <Grid container justifyContent="flex-end" columnGap={'8px'}>
              <Grid item>
                <THQDialogActionButton variant="standard" onClick={handleOnConfirmSecondary}>
                  <Typography
                    sx={(theme) => ({ fontSize: '14px', fontWeight: 700, color: theme.palette.common.midnight })}
                  >
                    Restart watch mode
                  </Typography>
                </THQDialogActionButton>
              </Grid>
              <Grid item>
                <THQDialogActionButton color={'error'} onClick={handleOnConfirm}>
                  <Typography
                    sx={(theme) => ({ fontSize: '14px', fontWeight: 700, color: theme.palette.error.foreground })}
                  >
                    Skip watch mode
                  </Typography>
                </THQDialogActionButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </THQDialogActions>
    </THQDialog>
  );
};

export default ErrorInSpeechSynthesisDialog;
