import React from 'react';

import { Grid } from '@mui/material';
import { THQCourseCardGhost, THQTabPanel } from '@trainhq/trainhq-client-core';

import noCompletedCourses from '@/assets/images/no_completed_courses.png';
import satelite from '@/assets/images/satelite.png';
import ContentBuilderItem from '@/components/common/contentBuilderList/contentBuilderItem/ContentBuilderItem';
import EmptyCard from '@/components/common/emptyCard/EmptyCard';
import { LearnerContentBuilder } from '@/models/learnerModels';

interface CourseListTabPanelProps {
  contentBuilders?: LearnerContentBuilder[];
  index: number;
  loading?: boolean;
  returnLink?: string;
  tabValue: number;
}

// TODO: use context to prevent prop drilling
const ContentBuilderListTabPanel: React.FC<CourseListTabPanelProps> = ({
  contentBuilders,
  index,
  loading,
  returnLink,
  tabValue
}) => {
  return (
    <THQTabPanel sx={{ overflowY: 'auto' }} value={tabValue} index={index}>
      <Grid sx={{ marginTop: 0, marginBottom: '64px' }} container spacing={2}>
        {loading && (
          <>
            <Grid item lg={4} md={6} sm={6} xs={12}>
              <THQCourseCardGhost />
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={12}>
              <THQCourseCardGhost />
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={12}>
              <THQCourseCardGhost />
            </Grid>
          </>
        )}
        {!loading && (!contentBuilders || contentBuilders?.length === 0) && (
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <EmptyCard
              description={
                index === 0
                  ? 'There aren’t any courses assigned to you yet.'
                  : 'Once you complete a course it will appear here'
              }
              image={index === 0 ? satelite : noCompletedCourses}
            />
          </Grid>
        )}
        {!loading &&
          contentBuilders &&
          contentBuilders?.map((contentBuilder) => (
            <ContentBuilderItem
              contentBuilder={contentBuilder}
              key={contentBuilder?.journey?.uuid || contentBuilder?.course?.uuid || contentBuilder?.rolePlay?.uuid}
              returnLink={returnLink}
            />
          ))}
      </Grid>
    </THQTabPanel>
  );
};

export default ContentBuilderListTabPanel;
