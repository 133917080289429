import React from 'react';

import { FeatureFlag, FeaturePermission } from '@trainhq/trainhq-client-core';

import NotFoundPage from '@/pages/notFound/NotFoundPage';
import RolePlay from '@/roleplay/RolePlay';

const RolePlayPage: React.FC = () => {
  return (
    <FeatureFlag featurePermissions={[FeaturePermission.ROLE_PLAY]} render={<NotFoundPage />}>
      <RolePlay />
    </FeatureFlag>
  );
};

export default RolePlayPage;
