import React, { useCallback } from 'react';

import { Grid, TableRow } from '@mui/material';
import { floorDecimals, getFormattedDateString, THQPrimaryButton } from '@trainhq/trainhq-client-core';

import { CALLS } from '@/constants/router';
import { useAuthenticatedUserContext } from '@/context/providers/AuthUserProvider';
import { ProgressTableCell } from '@/roleplay/naturalRolePlay/scorecardTabs/styles';

export interface CallsRowProps {
  callEntity: any;
}

const CallsRow: React.FC<CallsRowProps> = ({ callEntity }) => {
  const { activeCompany } = useAuthenticatedUserContext();

  const handleNavigateCallView = useCallback(() => {
    Object.assign(document.createElement('a'), {
      target: '_blank',
      rel: 'noopener noreferrer',
      href: `${CALLS}/${callEntity?.validationReportUuid}`
    }).click();
  }, [callEntity?.validationReportUuid]);

  return (
    <>
      <TableRow>
        <ProgressTableCell>{callEntity?.callTypeLabel?.name || callEntity.createdAt || '-'}</ProgressTableCell>
        <ProgressTableCell>
          {callEntity.callDate
            ? getFormattedDateString({
                dueDate: callEntity.callDate,
                noWeekWrap: true,
                shortMonth: true
              })
            : '-'}
        </ProgressTableCell>
        <ProgressTableCell>{callEntity.customer || '-'}</ProgressTableCell>
        <ProgressTableCell>
          {callEntity?.successRate !== undefined ? `${floorDecimals(callEntity?.successRate, 2) + '%'}` : '-'}
        </ProgressTableCell>
        {activeCompany?.companyConfiguration?.deleteCallIntelligenceSourceData && (
          <>
            <ProgressTableCell>{callEntity.externalCallUrl || '-'}</ProgressTableCell>
            <ProgressTableCell>{callEntity.callId || '-'}</ProgressTableCell>
          </>
        )}
        <ProgressTableCell width={140}>
          {callEntity?.validationReportUuid && (
            <Grid container alignItems={'center'} columnGap={'8px'} justifyContent="center">
              <Grid item xs="auto">
                <THQPrimaryButton size="small" onClick={handleNavigateCallView}>
                  View {'->'}
                </THQPrimaryButton>
              </Grid>
            </Grid>
          )}
        </ProgressTableCell>
      </TableRow>
    </>
  );
};

export default CallsRow;
