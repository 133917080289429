import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Fade, Typography } from '@mui/material';
import { Box } from '@mui/system';
import {
  getJourneyItemImage,
  isOpenaiVoice,
  JourneyItemType,
  RestartRPDialog,
  RPEventType,
  RPMessage,
  THQCard,
  THQPrimaryButton,
  THQProgress,
  THQTooltip,
  useTextToSpeech,
  XIcon
} from '@trainhq/trainhq-client-core';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { map } from 'rxjs';

import {
  RPButton,
  RPChatContainer,
  RPContainer,
  RPRecordButtonContainer,
  RPRecordedActionsBar,
  WindmillLoader
} from '../styles';
import { ReactComponent as Mic } from '@/assets/icons/mic.svg';
import { ReactComponent as Restart } from '@/assets/icons/restart.svg';
import { ReactComponent as Stop } from '@/assets/icons/stop.svg';
import BotImageFemale from '@/assets/images/bot_female.png';
import BotImageMale from '@/assets/images/bot_male.png';
import RPContextNarrator from '@/assets/images/rp_context_narrator.png';
import UserImageFemale from '@/assets/images/user_female.png';
import UserImageMale from '@/assets/images/user_male.png';
import { TEXT_TO_SPEECH } from '@/constants/api';
import { ROLEPLAYS } from '@/constants/router';
import { useAuthenticatedUserContext } from '@/context/providers/AuthUserProvider';
import { useAzureSDKContext } from '@/hooks/azureSDK/useAzureSDKContext';
import { useRolePlayService } from '@/hooks/roleplay/useRolePlayService';
import { RPRunSummary } from '@/roleplay/components/rpRunSummary/RPRunSummary';

interface RolePlayProps {
  initializing: boolean;
  setInitializing: React.Dispatch<React.SetStateAction<boolean>>;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
  sessionUuid: string;
}

const StrictRolePlay: React.FC<RolePlayProps> = ({ initializing, setInitializing, setError, sessionUuid }) => {
  const navigate = useNavigate();
  const {
    recognizer,
    getRecognizer,
    getSynth,
    getSsmlMessage,
    roleplayContext,
    roleplayConfig,
    roleplaySummary,
    refreshRoleplaySummary,
    setRoleplaySummary,
    roleplayPairContextMedia
  } = useAzureSDKContext();
  const [searchParams] = useSearchParams();
  const returnTo = searchParams.get('returnTo');
  const v2Api = !!searchParams.get('api');
  const user = useAuthenticatedUserContext();

  const chatContainer = useRef<HTMLDivElement>(null);
  const scrollObserver = useRef<MutationObserver>(null);

  const botImage = searchParams.get('bot') ?? roleplayConfig?.botImage ?? 'f';
  const userImage = searchParams.get('user') ?? roleplayConfig?.userImage ?? 'm';

  const [recognizedSpeech, setRecognizedSpeech] = useState('');
  const [recording, setRecording] = useState(false);
  const [startingRecord, setStartingRecord] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);
  const [chatStatus, setChatStatus] = useState('');
  const [fadeChat, setFadeChat] = useState(false);
  const [lastUserInput, setLastUserInput] = useState('');
  const [inputHint, setInputHint] = useState('');
  const [speechEndDetected, setSpeechEndDetected] = useState(false);
  const [sessionStarted, setSessionStarted] = useState(false);
  const responseButtonRef = useRef<HTMLButtonElement>(null);
  const recordButtonRef = useRef<HTMLButtonElement>(null);
  const [showHint, setShowHint] = useState(false);
  const [editing, setEditing] = useState(false);
  const [coveredSteps, setCoveredSteps] = useState([]);
  const [initialSentence, setInitialSentence] = useState(false);
  const [failPresent, setFailPresent] = useState(false);
  const [errorInCommunication, setErrorInCommunication] = useState(false);
  const [restartRPDialogOpen, setRestartRPDialogOpen] = useState(false);

  const [waitForReponse, setWaitForReponse] = useState(false);

  const rpService = useRolePlayService();
  const tts = useTextToSpeech({ url: TEXT_TO_SPEECH });
  const recog = useRef(recognizer);

  // TODO: fix deps
  const readMessage = useCallback(
    (message: string, chatMessages: string[], expression?: string) => {
      console.log('Getting synth...');
      if (isOpenaiVoice(roleplayConfig.botVoice)) {
        tts.play({ message, voice: roleplayConfig.botVoice });
      } else {
        getSynth().subscribe({
          next: (synth) => {
            synth.speakSsmlAsync(
              getSsmlMessage(message, expression ?? 'general'),
              function (result) {
                if (result.reason === window.SpeechSDK.ResultReason.Canceled) {
                  console.error('synthesis failed. Error detail: ' + result.errorDetails + '\n');
                }
                if (chatMessages) {
                  setChatMessages(chatMessages);
                }
                console.log('Closing synth....');
                synth.close();
              },
              function (err) {
                window.console.error(err);
              }
            );
          }
        });
      }
    },
    [getSsmlMessage, getSynth]
  );

  const handleInit = useCallback(
    (initialContextMedia: any) => {
      rpService.init(sessionUuid).subscribe({
        next: (res) => {
          setInitializing(false);
          if (res?.message) {
            setInitialSentence(true);
            const newChatMessages: any[] = [{ sender: 'bot', content: res?.message }];
            if (initialContextMedia) {
              const initialContextMediaHints = initialContextMedia
                .map((item) => ({
                  sender: 'narrator',
                  media: item,
                  introText: item.introText,
                  content: item.text ?? 'here is a hint for you',
                  highlight: false,
                  primary: false,
                  side: 'left'
                }))
                .reverse();
              initialContextMediaHints.forEach((item) => newChatMessages.unshift(item));
            }
            setChatMessages(newChatMessages);
          } else if (initialContextMedia) {
            setChatMessages(
              initialContextMedia.map((item) => ({
                sender: 'narrator',
                media: item,
                introText: item.introText,
                content: item.text ?? 'here is a hint for you',
                highlight: false,
                primary: false,
                side: 'left'
              }))
            );
          }
        },
        error: () => {
          setError(true);
        }
      });
    },
    [rpService, sessionUuid, setError, setInitializing]
  );

  const initRolePlay = useCallback(
    (reset?: boolean) => {
      let initialContextMedia = null;
      if (roleplayConfig.script?.stepUuids) {
        initialContextMedia = roleplayPairContextMedia.filter((item) => {
          return roleplayConfig.script?.stepUuids?.[0] === item.stepUuid;
        });
      } else {
        const initialArray = roleplayPairContextMedia.filter((item) => item.stepNumber === 0);
        initialContextMedia = initialArray.length > 0 ? initialArray : null;
      }

      setChatStatus('');
      setLastUserInput('');
      setRecognizedSpeech('');
      setChatMessages([]);
      setCoveredSteps([]);
      setFailPresent(false);
      setRecording(false);
      setWaitForReponse(false);
      if (reset) {
        refreshRoleplaySummary().subscribe({
          next: () => {
            handleInit(initialContextMedia);
          }
        });
      } else {
        handleInit(initialContextMedia);
      }
    },
    [roleplayConfig.script?.stepUuids, roleplayPairContextMedia, refreshRoleplaySummary, handleInit]
  );

  const fadeOutChatAndReset = () => {
    setFadeChat(true);
    setRestartRPDialogOpen(false);
    setTimeout(() => {
      setFadeChat(false);
      initRolePlay(true);
    }, 1000);
  };

  useEffect(() => {
    initRolePlay();
  }, [initRolePlay]);

  const startRecording = () => {
    setStartingRecord(true);
    continuousRecognitionStart().subscribe();
  };

  const continuousRecognitionStop = useCallback(() => {
    console.info('recognition ended');
    recordButtonRef.current?.blur();
    recog.current?.stopContinuousRecognitionAsync();
  }, []);

  const stopRecording = useCallback(() => {
    setRecording(false);
    continuousRecognitionStop();
    recordButtonRef.current?.blur();
    responseButtonRef.current?.focus();
  }, [continuousRecognitionStop]);

  const continuousRecognitionStart = () => {
    return getRecognizer().pipe(
      map((r) => {
        if (r) {
          recog.current = r;
          r.recognized = (recognizer, event) => {
            if (event.result.text) {
              setRecognizedSpeech((prevState) => `${prevState} ${event.result.text}`);
            }
          };

          r.speechEndDetected = () => {
            setSpeechEndDetected(true);
          };

          r.sessionStopped = () => {
            setSessionStarted(false);
          };
          r.sessionStarted = () => {
            setSessionStarted(true);
          };
        }

        r.startContinuousRecognitionAsync(
          () => {
            console.log('recognition started');
            setStartingRecord(false);
            setRecording(true);
          },
          (error) => {
            console.log(error, 'err');
          }
        );
      })
    );
  };

  useEffect(() => {
    return () => stopRecording();
  }, [stopRecording]);

  const prepBotHistory = (hasInitialSentence?: boolean) => {
    const botComms = [...chatMessages];
    const filteredBotComs = botComms
      .filter((item) => item.sender !== 'narrator')
      .map((item) => {
        if (item.sender === 'me') {
          return { role: 'user', content: item.content };
        } else {
          return { role: 'assistant', content: item.content };
        }
      })
      .filter((i) => !!i)
      .reverse();
    if (hasInitialSentence) {
      botComms.pop();
    }

    return filteredBotComs;
  };

  const getMessageAvatar = (identifier: string) => {
    switch (identifier) {
      case 'me':
        return getUserImage();
      case 'narrator':
        return RPContextNarrator;
      case 'bot':
      default:
        return getBotImage();
    }
  };
  const getUserImage = useCallback(() => {
    if (userImage.length > 2) {
      return userImage;
    }
    return userImage === 'm' ? UserImageMale : UserImageFemale;
  }, [userImage]);

  const getBotImage = useCallback(() => {
    if (botImage.length > 2) {
      return botImage;
    }
    return botImage === 'm' ? BotImageMale : BotImageFemale;
  }, [botImage]);

  const handleChangeRecognizedSpeech = useCallback((recSpeechEdit: string) => {
    setRecognizedSpeech(recSpeechEdit);
    setEditing(false);
  }, []);

  const enqueueAddHintToChat = (hintMessage) => {
    setTimeout(() => {
      setChatMessages((previousChatMessages) => [hintMessage, ...previousChatMessages]);
    }, 2500);
  };

  const handleSubmitMessage = () => {
    setWaitForReponse(true);
    setLastUserInput(recognizedSpeech);
    const history = prepBotHistory(initialSentence);
    setChatMessages([
      '',
      { content: recognizedSpeech, sender: 'me' },
      ...chatMessages.filter((item, index) => item.content || index === 0)
    ]);
    rpService
      .respond(
        {
          sessionUuid,
          step: Math.floor(initialSentence ? history.length / 2 : (history.length + 1) / 2),
          covered_steps: coveredSteps,
          company: user?.activeCompany?.uuid,
          learner_input: recognizedSpeech,
          fail_occurred: failPresent
        },
        v2Api
      )
      .subscribe({
        next: (res) => {
          if (res?.status === null) {
            setErrorInCommunication(true);
            setChatStatus('failure');
            return;
          }
          setWaitForReponse(false);
          if (res?.status) {
            setChatStatus(res?.status);
          }
          if (res?.status === 'proceed') {
            setShowHint(false);
            if (res.actual_step || res.actual_step === 0) {
              setCoveredSteps([...coveredSteps, res.actual_step]);
            }
            const currentStep = Math.floor(initialSentence ? (history.length + 1) / 2 : history.length / 2 + 1);
            const relevantPairContextMedia =
              roleplayPairContextMedia?.find((item) => {
                return item.stepNumber === currentStep;
              }) ?? undefined;

            if (relevantPairContextMedia) {
              enqueueAddHintToChat({
                content: relevantPairContextMedia.text ?? ' ',
                media: relevantPairContextMedia,
                sender: 'narrator'
              });
            }
            const newChatMessages = [
              { content: res.message.replace(/^\n|\n$/g, ''), sender: 'bot' },
              { content: recognizedSpeech.replace(/^\n|\n$/g, ''), sender: 'me' },
              ...chatMessages
            ];
            readMessage(res.message, newChatMessages, res.expression ?? roleplayConfig?.overallExpression ?? 'general');
          } else {
            if (res?.status === 'failure' && !roleplaySummary.completed) {
              setFailPresent(true);
              setRoleplaySummary({ ...roleplaySummary, finishedInARow: 0 });
              refreshRoleplaySummary().subscribe();
            }
            setInputHint(res?.message);
            if (!failPresent && res?.status !== null && res?.status !== 'failure') {
              setRoleplaySummary({
                ...roleplaySummary,
                finishedInARow: roleplaySummary.finishedInARow + 1
              });
            }
            setChatMessages([{ sender: 'me', content: recognizedSpeech }, ...chatMessages]);
            if (res?.status !== 'failure' && (res.actual_step || res.actual_step === 0)) {
              setCoveredSteps([...coveredSteps, res.actual_step]);
            }
          }
          recordButtonRef?.current?.focus();
        },
        error: (e) => {
          setWaitForReponse(false);
          setErrorInCommunication(true);
          setChatStatus('failure');
          console.log(e);
        }
      });
    setRecognizedSpeech('');
    setSpeechEndDetected(false);
    recordButtonRef?.current?.focus();
  };

  const handleResetSubmit = () => {
    setRecognizedSpeech('');
    recordButtonRef?.current?.focus();
    setSpeechEndDetected(false);
  };

  const undoLastMessage = (errorPresent?: boolean) => {
    const newChatMessages = [...chatMessages];
    newChatMessages.shift();
    if (errorPresent) {
      newChatMessages.shift();
    }
    setErrorInCommunication(false);
    setChatStatus('');
    setLastUserInput('');
    setRecognizedSpeech('');
    setRecording(false);
    setWaitForReponse(false);
    setChatMessages(newChatMessages);
    setShowHint(true);
  };

  const toggleEditing = () => {
    setEditing(!editing);
  };

  const handleGoBackToRoleplays = useCallback(() => {
    stopRecording();
    if (returnTo) {
      navigate(returnTo);
    } else {
      navigate(ROLEPLAYS);
    }
  }, [navigate, returnTo, stopRecording]);

  const logHintEvent = () => {
    const history = prepBotHistory();

    const payload = {
      actualStep: coveredSteps[coveredSteps.length - 1],
      stepNumber: Math.floor(initialSentence ? history.length / 2 : (history.length + 1) / 2),
      botResponse: inputHint,
      eventType: RPEventType.HINT,
      company: user?.activeCompany?.uuid,
      journeyUuid: roleplaySummary?.journeyUuid,
      rolePlayUuid: roleplayConfig.rolePlayUuid,
      roleplayID: roleplayConfig.rolePlayID,
      userInput: lastUserInput
    };

    return rpService.logAction(payload);
  };

  const formRoleplayContext = useMemo(() => {
    return roleplayContext.split(/\n/gm).map((item, index) => (
      <React.Fragment key={'' + index}>
        <span>{item}</span>
        <br />
      </React.Fragment>
    ));
  }, [roleplayContext]);

  const toggleRestartRPDialog = () => {
    setRestartRPDialogOpen((prev) => !prev);
  };

  const scrollOnEvent = () => {
    const target = chatContainer.current;
    setTimeout(() => {
      target.scroll({ top: target.scrollHeight + 200, behavior: 'smooth' });
    }, 120);
  };

  // TODO: fix deps
  useEffect(() => {
    if (chatContainer.current) {
      scrollObserver.current = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
          const nodes = Array.prototype.slice.call(mutation.addedNodes);
          nodes.forEach(function () {
            scrollOnEvent();
          });
        });
      });
      scrollObserver.current.observe(chatContainer.current, {
        childList: true,
        subtree: true,
        attributes: false,
        characterData: false
      });
    }
    return () => {
      scrollObserver.current?.disconnect();
    };
  }, [chatContainer.current]);

  return (
    <>
      {!initializing && (
        <RPContainer accentedBackground={waitForReponse}>
          <div>
            <RestartRPDialog
              open={restartRPDialogOpen}
              handleOnClose={toggleRestartRPDialog}
              handleOnConfirm={fadeOutChatAndReset}
            />
            <THQCard
              cardSx={(theme) => ({
                '&&&': {
                  maxWidth: '340px'
                },
                ['&:hover']: {
                  backgroundColor: `${theme.palette.common.zima}`,
                  transition: 'all .3s ease-in'
                },
                transition: 'all .3s ease-out',
                backgroundColor: `${theme.palette.common.zima}4D`,
                width: 'calc(100vw - 40px)',
                position: 'fixed',
                top: '26px',
                left: '20px',
                [theme.breakpoints.down('sm')]: {
                  maxWidth: '300px',
                  backgroundColor: `${theme.palette.common.zima}`
                }
              })}
              description={
                <THQTooltip arrow title={<>{roleplayConfig?.name}</>}>
                  <Typography
                    fontSize={12}
                    fontWeight={600}
                    sx={(theme) => ({
                      [theme.breakpoints.down('sm')]: { maxWidth: '160px' },
                      maxWidth: '240px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap'
                    })}
                  >
                    {roleplayConfig?.name}
                  </Typography>
                </THQTooltip>
              }
              icon={
                <img
                  alt="journey-item"
                  src={getJourneyItemImage(JourneyItemType.ROLE_PLAY)}
                  style={{ objectFit: 'cover', height: '40px', width: '40px' }}
                />
              }
              orientation="horizontal"
              title={
                <Typography fontSize={12} fontWeight={400} sx={(theme) => ({ color: theme.palette.common.silver })}>
                  Role-play
                </Typography>
              }
            />
            <THQPrimaryButton sx={{ position: 'fixed', top: '40px', right: '40px' }} onClick={handleGoBackToRoleplays}>
              <XIcon style={{ width: '16px', height: '16px' }} />
            </THQPrimaryButton>
            <THQPrimaryButton
              sx={{
                position: 'fixed',
                bottom: '40px',
                right: '40px',
                opacity: chatStatus === 'failure' || errorInCommunication ? 1 : 0,
                pointerEvents: chatStatus === 'failure' || errorInCommunication ? 'unset' : 'none',
                transition: 'all .3s ease-out'
              }}
              onClick={toggleRestartRPDialog}
              disabled={recording}
            >
              <Restart style={{ width: '14px', height: '14px', marginRight: '8px' }} />
              Restart
            </THQPrimaryButton>
            <RPChatContainer ref={chatContainer} fadeChat={fadeChat}>
              <RPRunSummary
                roleplayConfig={roleplayConfig}
                roleplaySummary={roleplaySummary}
                errorInCommunication={errorInCommunication}
                logHintEvent={logHintEvent}
                failPresent={failPresent}
                actualMessage={lastUserInput}
                preferredMessage={inputHint}
                onMainClick={fadeOutChatAndReset}
                chatStatus={chatStatus}
                showHint={showHint}
                onSecondaryClick={handleGoBackToRoleplays}
                onRetryLast={undoLastMessage}
                visible={chatStatus === 'failure' || chatStatus === 'success' || errorInCommunication}
              />

              {sessionStarted && (
                <RPMessage
                  avatar={getUserImage()}
                  content={''}
                  highlight={true}
                  primary={false}
                  side={'right'}
                  user={user}
                />
              )}

              {recognizedSpeech && speechEndDetected && !recording && (
                <RPMessage
                  editable
                  onToggleEditing={toggleEditing}
                  commitEdit={handleChangeRecognizedSpeech}
                  avatar={getUserImage()}
                  content={recognizedSpeech}
                  highlight={true}
                  primary={true}
                  side={'right'}
                  user={user}
                />
              )}

              {chatMessages
                .filter((item, index) => item.content || index === 0)
                .map((cm, index) => (
                  <RPMessage
                    avatar={getMessageAvatar(cm.sender)}
                    key={'' + cm.uuid + '-' + (cm.content ?? '') + (cm.media?.uuid ? cm.media?.uuid : '' + index)}
                    media={cm.media}
                    introText={cm.introText}
                    content={cm.content}
                    highlight={cm.highlight}
                    primary={cm?.primary !== undefined ? cm?.primary : cm.sender === 'me'}
                    side={cm.sender === 'me' ? 'right' : 'left'}
                    user={user}
                  />
                ))}
              <RPMessage
                lessImportant
                maxExpand
                avatar={RPContextNarrator}
                key={'' + 'roleplayContext_outro'}
                content={'Press the microphone icon below to start.'}
                highlight={false}
                primary={false}
                side={'left'}
                user={user}
              />
              {roleplayContext && (
                <RPMessage
                  lessImportant
                  maxExpand
                  avatar={RPContextNarrator}
                  key={'' + 'roleplayContext'}
                  content={formRoleplayContext}
                  highlight={false}
                  primary={false}
                  side={'left'}
                  user={user}
                />
              )}
              {roleplayContext && (
                <RPMessage
                  lessImportant
                  maxExpand
                  avatar={RPContextNarrator}
                  key={'' + 'roleplayContext_intro'}
                  content={'Hi! I’m your TrainHQ Assistant. I’m here to share some context about this role-play.'}
                  highlight={false}
                  primary={false}
                  side={'left'}
                  user={user}
                />
              )}
            </RPChatContainer>
            {chatStatus !== 'failure' && chatStatus !== 'success' && (
              <div>
                <RPRecordedActionsBar recognizedSpeech={recognizedSpeech} recording={recording}>
                  <THQPrimaryButton
                    disabled={editing || !recognizedSpeech}
                    ref={responseButtonRef}
                    variant="main"
                    onClick={handleSubmitMessage}
                  >
                    Send response
                  </THQPrimaryButton>
                  <div style={{ display: 'flex', gap: '12px' }}>
                    <THQPrimaryButton disabled={editing} onClick={handleResetSubmit}>
                      Try again
                    </THQPrimaryButton>
                  </div>
                </RPRecordedActionsBar>
                <RPRecordButtonContainer recognizedSpeech={recognizedSpeech} recording={recording}>
                  <RPButton
                    ref={recordButtonRef}
                    disabled={startingRecord || (recognizedSpeech && speechEndDetected && !recording && waitForReponse)}
                    recording={recording}
                    variant="main"
                    sx={{ position: 'relative' }}
                    onClick={recording ? stopRecording : startRecording}
                  >
                    <Fade in={!startingRecord && !recording} unmountOnExit>
                      <div style={{ position: 'absolute' }}>
                        <Mic />
                      </div>
                    </Fade>
                    <Fade in={!startingRecord && recording} unmountOnExit>
                      <div style={{ position: 'absolute' }}>
                        <Stop />
                      </div>
                    </Fade>
                    <Fade in={startingRecord} unmountOnExit>
                      <div>
                        <WindmillLoader />
                      </div>
                    </Fade>
                  </RPButton>
                </RPRecordButtonContainer>
              </div>
            )}
            <Box sx={{ position: 'fixed', bottom: '40px', left: '40px' }}>
              <THQTooltip arrow title={'Successfully completed without error'}>
                <THQProgress
                  asFraction={true}
                  total={roleplayConfig.passRequirement}
                  passed={
                    roleplaySummary.completed
                      ? roleplayConfig.passRequirement
                      : roleplaySummary.finishedInARow > roleplayConfig.passRequirement
                      ? roleplayConfig.passRequirement
                      : roleplaySummary.finishedInARow
                  }
                  size={48}
                  variant="determinate"
                  value={
                    roleplaySummary.completed
                      ? 100
                      : roleplaySummary.finishedInARow > roleplayConfig.passRequirement
                      ? 100
                      : (roleplaySummary.finishedInARow /
                          (roleplayConfig.passRequirement === 0 ? 1 : roleplayConfig.passRequirement)) *
                        100
                  }
                />
              </THQTooltip>
            </Box>
          </div>
        </RPContainer>
      )}
    </>
  );
};

export default StrictRolePlay;
