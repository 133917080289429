import React, { useCallback, useEffect, useState } from 'react';

import { EmptyStateTemplate, RoleplayExecutionConfig, useAzureSDK } from '@trainhq/trainhq-client-core';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { catchError, map, mergeMap, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import notFoundHeroImg from '@/assets/images/not_found_hero.png';
import RolePlayInactivityDialog from '@/components/roleplays/inactivityDialog/RolePlayInactivityDialog';
import { HOME } from '@/constants/router';
import { AzureSDKContext } from '@/context/azureSDKContext';
import { useCreateRolePlayAndGetConfig, useRolePlayService } from '@/hooks/roleplay/useRolePlayService';
import { RPLoader } from '@/roleplay/components/loader/RPLoader';
import NaturalRolePlay from '@/roleplay/naturalRolePlay/NaturalRolePlay';
import NaturalRolePlayStart from '@/roleplay/naturalRolePlay/naturalRolePlayStart/NaturalRolePlayStart';
import { loaderMessages } from '@/roleplay/naturalRolePlay/utils';
import StrictRolePlay from '@/roleplay/strict/StrictRolePlay';

const RolePlay: React.FC = () => {
  const [searchParams] = useSearchParams();
  const roleplayID = searchParams.get('roleplay');
  const journeyUuid = searchParams.get('journey');
  const rpService = useRolePlayService();
  const [initializing, setInitializing] = useState(true);
  const [practiceMode, setPracticeMode] = useState(false);
  const [sessionUuid, setSessionUuid] = useState<string>();
  const [roleplayExecutionConfig, setRoleplayExecutionConfig] = useState<RoleplayExecutionConfig>(null);
  const [serverError, setServerError] = useState(false);

  const createSessionAndFetchConfig = useCallback(() => {
    return rpService.createRolePlaySession(roleplayID, journeyUuid).pipe(
      mergeMap((session) => {
        setSessionUuid(session.uuid);
        return rpService.getStt(session.uuid);
      }),
      tap((res) => {
        setRoleplayExecutionConfig(res);
      }),
      catchError((err) => {
        if (err.status === 404) {
          setRoleplayExecutionConfig(null);
        } else {
          setServerError(true);
        }
        return of(null);
      })
    );
  }, [journeyUuid, roleplayID, rpService]);

  useCreateRolePlayAndGetConfig(createSessionAndFetchConfig);

  const {
    speechToText: recognizer,
    getRecognizer,
    textToSpeech: synthesizer,
    getSynth,
    getPlayer,
    providePlayerEndCallback,
    getSsmlMessage
  } = useAzureSDK(roleplayExecutionConfig, rpService);
  const navigate = useNavigate();

  const handleGoHome = useCallback(() => {
    navigate(HOME);
  }, [navigate]);

  const refreshRoleplaySummary = useCallback(() => {
    return rpService.refreshRoleplaySummary(roleplayID, journeyUuid).pipe(
      map((res) => {
        setRoleplayExecutionConfig((prev) => ({ ...prev, userSummary: res }));
      })
    );
  }, [roleplayID, journeyUuid, rpService]);

  const setRoleplaySummary = useCallback((newRpSummary) => {
    setRoleplayExecutionConfig((prev) => ({ ...prev, userSummary: newRpSummary }));
  }, []);

  useEffect(() => {
    if (!roleplayExecutionConfig) {
      return;
    }
  }, [roleplayExecutionConfig]);

  const moveToPracticeMode = () => {
    setPracticeMode(true);
  };

  const moveOutOfPracticeMode = useCallback(() => {
    setPracticeMode(false);
    setSessionUuid(null);
    createSessionAndFetchConfig().subscribe();
  }, [createSessionAndFetchConfig]);

  return (
    <RolePlayInactivityDialog>
      <AzureSDKContext.Provider
        value={{
          recognizer,
          synthesizer,
          getRecognizer,
          getSynth,
          getPlayer,
          getSsmlMessage,
          roleplayExecutionConfig: roleplayExecutionConfig,
          roleplayContext: roleplayExecutionConfig?.context,
          roleplayConfig: roleplayExecutionConfig?.roleplayConfig,
          roleplaySummary: roleplayExecutionConfig?.userSummary,
          scriptConfig: roleplayExecutionConfig?.scriptConfig[0],
          roleplayPairContextMedia: roleplayExecutionConfig?.pairContextMediaDTOList,
          setRoleplaySummary: setRoleplaySummary,
          providePlayerEndCallback,
          refreshRoleplaySummary,
          createSessionAndFetchConfig
        }}
      >
        <>
          {serverError ? (
            <>
              <EmptyStateTemplate
                title={'Server error'}
                description={'Looks like there is a server problem. Try again in a few minutes.'}
                heroImage={notFoundHeroImg}
                heroImageMaxWidth={240}
                buttonAction={handleGoHome}
                buttonText={'Go to dashboard'}
              />
            </>
          ) : (
            <>
              {roleplayExecutionConfig && (
                <>
                  {roleplayExecutionConfig?.roleplayConfig?.enabled && roleplayExecutionConfig?.userSummary ? (
                    <>
                      <RPLoader
                        totalLength={25}
                        visible={initializing && !serverError}
                        loaderMessages={loaderMessages}
                      />
                      {recognizer && synthesizer && sessionUuid ? (
                        <>
                          {roleplayExecutionConfig.rolePlayType === 'NATURAL' ? (
                            <>
                              {practiceMode ? (
                                <NaturalRolePlay
                                  initializing={initializing}
                                  rolePlayExecutionConfig={roleplayExecutionConfig}
                                  sessionUuid={sessionUuid}
                                  setInitializing={setInitializing}
                                  setError={setServerError}
                                  moveOutOfPracticeMode={moveOutOfPracticeMode}
                                />
                              ) : (
                                <NaturalRolePlayStart
                                  setError={setServerError}
                                  initializing={initializing}
                                  setInitializing={setInitializing}
                                  moveToPracticeMode={moveToPracticeMode}
                                />
                              )}
                            </>
                          ) : (
                            <StrictRolePlay
                              sessionUuid={sessionUuid}
                              setError={setServerError}
                              initializing={initializing}
                              setInitializing={setInitializing}
                            />
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <EmptyStateTemplate
                      title={"Role-play doesn't exist"}
                      description={'Seems like there is no role-play with that name'}
                      heroImage={notFoundHeroImg}
                      heroImageMaxWidth={240}
                      buttonAction={handleGoHome}
                      buttonText={'Go to dashboard'}
                    />
                  )}
                </>
              )}
            </>
          )}
        </>
      </AzureSDKContext.Provider>
    </RolePlayInactivityDialog>
  );
};

export default RolePlay;
