import React from 'react';

import { MainLayout } from '@trainhq/trainhq-client-core';
import { Outlet } from 'react-router-dom';

import DashboardSidebar from '@/components/common/sidebar/DashboardSidebar';

const SidebarLayout: React.FC = () => {
  return (
    <MainLayout sidebarContent={<DashboardSidebar />}>
      <Outlet />
    </MainLayout>
  );
};

export default SidebarLayout;
