import { LearnerFeedbackThread, MilestoneStatus, ProgressStatus } from '@trainhq/trainhq-client-core';

import { LearnerCourse } from '@/models/learnerModels';

export function resolveCourseState(course: LearnerCourse): ProgressStatus {
  //leaving function call in in case additional logic needs to be added
  if (!course) return ProgressStatus.NOT_STARTED;
  return course.courseStatus;
}

export function resolveAndMapFeedbackStatus(
  feedbackThread?: LearnerFeedbackThread
): 'NOT_STARTED' | 'COMPLETED' | 'SUBMITTED' | 'RETRY_REQUIRED' {
  if (!feedbackThread?.threadStatus) return 'NOT_STARTED';
  if (feedbackThread.threadStatus === 'ACCEPTED') return 'COMPLETED';
  if (feedbackThread.threadStatus === 'PENDING_RESPONSE' || feedbackThread.threadStatus === 'APPROVER_REJECTED') {
    return 'SUBMITTED';
  }
  return 'RETRY_REQUIRED';
}

export const getChipInfo = (progressState: ProgressStatus | MilestoneStatus, theme) => {
  switch (progressState) {
    case ProgressStatus.COMPLETED:
    case MilestoneStatus.APPROVED:
      return {
        text: 'Completed',
        background: theme.palette.success.background,
        foreground: theme.palette.success.foreground
      };
    case ProgressStatus.FEEDBACK_REQUIRED:
      return {
        text: 'Received feedback',
        background: theme.palette.warning2.background,
        foreground: theme.palette.warning2.foreground
      };
    case ProgressStatus.PENDING_RESPONSE:
    case MilestoneStatus.LEARNER_COMPLETED:
      return {
        text: 'Waiting for approval',
        background: theme.palette.warning.background,
        foreground: theme.palette.warning.foreground
      };
    case ProgressStatus.DUE:
      return {
        text: 'Overdue',
        background: theme.palette.error.background,
        foreground: theme.palette.error.foreground
      };
    case ProgressStatus.REJECTED:
    case MilestoneStatus.REJECTED:
      return {
        text: 'Rejected',
        background: theme.palette.error.background,
        foreground: theme.palette.error.foreground
      };
    case ProgressStatus.IN_PROGRESS:
      return {
        text: 'In progress',
        background: theme.palette.warning.background,
        foreground: theme.palette.warning.foreground
      };
    case ProgressStatus.NOT_STARTED:
    default:
      return {
        text: 'Not started',
        background: theme.palette.common.pearl,
        foreground: theme.palette.error.midnight
      };
  }
};
