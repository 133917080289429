import React from 'react';

import { Grid, Typography } from '@mui/material';

import { RecordingButtonStyled, RecordingStudioGridStyled } from '@/components/common/quizRecordingStudio/styles';

interface QuizRecordingStudioProps {
  type: 'audio' | 'video';
  onRecordStart(): void;
}

const QuizRecordingStudio: React.FC<QuizRecordingStudioProps> = ({ type, onRecordStart }) => {
  return (
    <RecordingStudioGridStyled alignItems="center" container rowSpacing={2}>
      <Grid alignSelf="end" item xs={12}>
        <Typography align="center">
          Record {type === 'audio' ? 'an' : 'a'} {type} response
        </Typography>
      </Grid>
      <Grid alignSelf="start" item xs={12}>
        <RecordingButtonStyled onClick={onRecordStart}>Start recording</RecordingButtonStyled>
      </Grid>
    </RecordingStudioGridStyled>
  );
};

export default QuizRecordingStudio;
