import React from 'react';

import { Typography, Grid } from '@mui/material';
import {
  THQDialog,
  THQDialogTitle,
  THQDialogContent,
  THQDialogActions,
  THQDialogActionButton
} from '@trainhq/trainhq-client-core';
export interface SkipWatchDialogProps {
  open: boolean;
  handleOnClose?: () => void;
  handleOnConfirm?: () => void;
}

const SkipWatchDialog: React.FC<SkipWatchDialogProps> = ({ open, handleOnClose, handleOnConfirm }) => {
  return (
    <THQDialog fullWidth open={open} onClose={handleOnClose}>
      <THQDialogTitle>Skip watching?</THQDialogTitle>
      <THQDialogContent>
        <Typography>
          We recommend that you watch the role-play to get a good understanding of what is expected of you. Are you sure
          you want to skip?
        </Typography>
      </THQDialogContent>
      <THQDialogActions sx={{ borderTop: 'none' }}>
        <Grid container justifyContent="flex-end">
          <Grid item sm="auto" justifyContent="flex-end" xs={12}>
            <Grid container justifyContent="flex-end" columnGap={'8px'}>
              <Grid item>
                <THQDialogActionButton variant="standard" onClick={handleOnClose}>
                  <Typography
                    sx={(theme) => ({ fontSize: '14px', fontWeight: 700, color: theme.palette.common.midnight })}
                  >
                    Cancel
                  </Typography>
                </THQDialogActionButton>
              </Grid>
              <Grid item>
                <THQDialogActionButton color={'error'} onClick={handleOnConfirm}>
                  <Typography
                    sx={(theme) => ({ fontSize: '14px', fontWeight: 700, color: theme.palette.error.foreground })}
                  >
                    Skip anyway
                  </Typography>
                </THQDialogActionButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </THQDialogActions>
    </THQDialog>
  );
};
export default SkipWatchDialog;
