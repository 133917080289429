import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Grid, List } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  ArrowDown,
  Bell,
  Bookmark,
  FeatureFlag,
  FeaturePermission,
  Home,
  InsightsIcon,
  LibMenuItemIcon,
  RoleplayIcon,
  SidebarListItem,
  SideBarMain,
  THQAvatar,
  THQBody,
  THQDropdown,
  useChangePasswordDialog,
  useFeaturesEnabled
} from '@trainhq/trainhq-client-core';
import { useLocation, useNavigate } from 'react-router-dom';
import { finalize, Subscription } from 'rxjs';

import { ReactComponent as CallsIcon } from '@/assets/icons/headset.svg';
import { ReactComponent as Journey } from '@/assets/icons/journeys.svg';
import { ReactComponent as Logout } from '@/assets/icons/logout.svg';
import {
  CurrentUserContainer,
  ListGridStyled,
  LockIconStyled,
  LogoGridStyled,
  LogoutGridContainerStyled,
  SidebarContentRootGridStyled
} from '@/components/common/sidebar/styles';
import ThemeLogo from '@/components/common/themeLogo/ThemeLogo';
import { CALLS, COURSES, FEEDBACK, HOME, INSIGHTS, JOURNEYS, LIBRARY, ROLEPLAYS } from '@/constants/router';
import { useAuthenticatedUserContext } from '@/context/providers/AuthUserProvider';
import { useFeedbackService } from '@/hooks/feedback/useCourseService';
import { useGetPasswordPolicy, useUserManagementService } from '@/hooks/userManagement/useUserManagementService';
import { getRealmConfig } from '@/utils/urlUtils';

const pollRefreshInterval = 30000;

// TODO: extract journey icon to core
// TODO: refactor styling of the sidebar list items (move to styles.ts and use styled common)
const DashboardSidebar: React.FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const theme = useTheme();
  const user = useAuthenticatedUserContext();
  const changePasswordDialog = useChangePasswordDialog();

  const feedbackService = useFeedbackService();
  const userService = useUserManagementService();
  const courseFeatureEnabled = useFeaturesEnabled([FeaturePermission.COURSE]);
  const passPolicy = useGetPasswordPolicy();

  const [pendingCount, setPendingCount] = useState<number>();

  useEffect(() => {
    const intervalID = setInterval(() => {
      feedbackService.refreshFeedbackItemsCountSummary('cloud');
    }, pollRefreshInterval);
    return () => {
      clearInterval(intervalID);
    };
  }, [feedbackService]);

  useEffect(() => {
    const subs = new Subscription();

    if (courseFeatureEnabled) {
      subs.add(
        feedbackService.observeFeedbackSummary().subscribe({
          next: (summary) => {
            setPendingCount(summary.pendingItems > 0 ? summary.pendingItems : undefined);
          }
        })
      );
      feedbackService.refreshFeedbackItemsCountSummary();
    }

    return () => {
      subs.unsubscribe();
    };
  }, [courseFeatureEnabled, feedbackService]);

  const handleGoHome = useCallback(() => {
    navigate(HOME);
  }, [navigate]);

  const handleGoToFeedback = useCallback(() => {
    navigate(FEEDBACK);
  }, [navigate]);

  const handleGoToCourses = useCallback(() => {
    navigate(COURSES);
  }, [navigate]);

  const handleGoToCalls = useCallback(() => {
    navigate(CALLS);
  }, [navigate]);

  const handleGoToJourneys = useCallback(() => {
    navigate(JOURNEYS);
  }, [navigate]);

  const handleGoToRoleplays = useCallback(() => {
    navigate(ROLEPLAYS);
  }, [navigate]);

  const handleGoToInsights = useCallback(() => {
    navigate(INSIGHTS);
  }, [navigate]);

  const handleGoToLibrary = useCallback(() => {
    navigate(LIBRARY);
  }, [navigate]);

  const handleLogout = useCallback(() => {
    userService.logout().subscribe();
  }, [userService]);

  const realmConfig = useMemo(() => {
    return getRealmConfig();
  }, []);

  const handleOnChangePassword = useCallback(
    (oldPassword: string, newPassword: string, callback: () => void) => {
      userService
        .changePassword(oldPassword, newPassword)
        .pipe(
          finalize(() => {
            callback();
          })
        )
        .subscribe({
          next: () => {
            handleLogout();
          }
        });
    },
    [userService, handleLogout]
  );

  const handleOpenChangePasswordDialog = useCallback(() => {
    changePasswordDialog(passPolicy, true, handleOnChangePassword);
  }, [changePasswordDialog, handleOnChangePassword, passPolicy]);
  const dropDownItems = useMemo(() => {
    const items = [{ text: 'Change password', action: handleOpenChangePasswordDialog, icon: <LockIconStyled /> }];
    if (!realmConfig.idpHint) {
      items.push({ text: 'Logout', action: handleLogout, icon: <Logout /> });
    }
    return items;
  }, [handleLogout, handleOpenChangePasswordDialog, realmConfig.idpHint]);

  return (
    <SideBarMain
      contentFixed={true}
      expanded={true}
      content={
        <SidebarContentRootGridStyled container direction="column">
          <LogoGridStyled item>
            <Grid sx={{ marginLeft: '8px' }} alignItems="center" container spacing={1}>
              <Grid item>
                <ThemeLogo />
              </Grid>
            </Grid>
          </LogoGridStyled>
          <ListGridStyled item>
            <List component="nav">
              <SidebarListItem
                icon={
                  <Home
                    style={{
                      color: pathname === HOME ? theme.palette.primary.main : theme.palette.common.steel
                    }}
                  />
                }
                isSelected={pathname === HOME}
                title="Home"
                onClick={handleGoHome}
              />
              <FeatureFlag featurePermissions={[FeaturePermission.COURSE]}>
                <SidebarListItem
                  icon={
                    <Bell
                      style={{
                        color:
                          pathname === FEEDBACK || pathname.includes('feedback')
                            ? theme.palette.primary.main
                            : theme.palette.common.steel
                      }}
                    />
                  }
                  isSelected={pathname === FEEDBACK || pathname.includes('feedback')}
                  title="Feedback"
                  count={pendingCount}
                  onClick={handleGoToFeedback}
                />
              </FeatureFlag>
              <FeatureFlag featurePermissions={[FeaturePermission.COURSE]}>
                <SidebarListItem
                  icon={
                    <Bookmark
                      style={{
                        color: pathname.includes(COURSES) ? theme.palette.primary.main : theme.palette.common.steel
                      }}
                    />
                  }
                  isSelected={pathname.includes(COURSES)}
                  title="Courses"
                  onClick={handleGoToCourses}
                />
              </FeatureFlag>
              <FeatureFlag featurePermissions={[FeaturePermission.CALL_INTELLIGENCE]}>
                <SidebarListItem
                  icon={
                    <CallsIcon
                      style={{
                        width: '16px',
                        height: '16px',
                        color: pathname === CALLS ? theme.palette.primary.main : theme.palette.common.steel
                      }}
                    />
                  }
                  isSelected={pathname === CALLS}
                  title="Calls"
                  onClick={handleGoToCalls}
                />
              </FeatureFlag>
              <FeatureFlag featurePermissions={[FeaturePermission.JOURNEY]}>
                <SidebarListItem
                  icon={
                    <Journey
                      style={{
                        color: pathname === JOURNEYS ? theme.palette.primary.main : theme.palette.common.steel
                      }}
                    />
                  }
                  isSelected={pathname === JOURNEYS}
                  title="Journeys"
                  onClick={handleGoToJourneys}
                />
              </FeatureFlag>
              <FeatureFlag featurePermissions={[FeaturePermission.ROLE_PLAY]}>
                <SidebarListItem
                  icon={
                    <RoleplayIcon
                      style={{
                        color: pathname.includes(ROLEPLAYS) ? theme.palette.primary.main : theme.palette.common.steel
                      }}
                    />
                  }
                  isSelected={pathname.includes(ROLEPLAYS)}
                  title="Role-plays"
                  onClick={handleGoToRoleplays}
                />
              </FeatureFlag>
              <FeatureFlag featurePermissions={[FeaturePermission.ROLE_PLAY]}>
                <SidebarListItem
                  icon={
                    <InsightsIcon
                      style={{
                        color: pathname.includes(INSIGHTS) ? theme.palette.primary.main : theme.palette.common.steel
                      }}
                    />
                  }
                  isSelected={pathname.includes(INSIGHTS)}
                  title="Insights"
                  onClick={handleGoToInsights}
                />
              </FeatureFlag>
              <SidebarListItem
                icon={
                  <LibMenuItemIcon
                    style={{
                      color: pathname === LIBRARY ? theme.palette.primary.main : theme.palette.common.steel
                    }}
                  />
                }
                isSelected={pathname === LIBRARY}
                title="Library"
                onClick={handleGoToLibrary}
              />
            </List>
          </ListGridStyled>
          <Grid item>
            <LogoutGridContainerStyled container alignItems="center" direction="row" spacing={1}>
              <CurrentUserContainer>
                <THQAvatar src={user?.profilePicUrl} colorRandomizeString={`${user?.firstName + user?.lastName}`} />
                <div>
                  <THQBody
                    sx={{
                      color: theme.palette.common.midnight,
                      maxWidth: '135px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    {user?.firstName} {user?.lastName}
                  </THQBody>
                  <THQBody
                    sx={{
                      color: theme.palette.common.steel,
                      fontSize: '12px',
                      overflow: 'hidden',
                      maxWidth: '135px',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    {user?.email}
                  </THQBody>
                </div>
                <div>
                  <THQDropdown
                    button={{
                      sx: { border: 'none', minWidth: '24px', minHeight: '24px', padding: 0 },
                      icon: <ArrowDown style={{ color: theme.palette.common.steel }} />
                    }}
                    items={dropDownItems}
                  />
                </div>
              </CurrentUserContainer>
            </LogoutGridContainerStyled>
          </Grid>
        </SidebarContentRootGridStyled>
      }
    />
  );
};

export default DashboardSidebar;
