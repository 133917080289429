import React, { useMemo, useState } from 'react';

import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ProgressStatus, THQTab, THQTabContainer } from '@trainhq/trainhq-client-core';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { StandaloneContainerStyled } from '@/components/common/contentBuilderList/styles';
import RoleplayListTabPanel from '@/components/roleplays/roleplayList/roleplayListTabPanel/RoleplayListTabPanel';
import { useGetAllRoleplays } from '@/hooks/roleplay/useRolePlayService';

// TODO: pass isStandalone and return link trough context
export const RoleplayList: React.FC = () => {
  const { rolePlays, loading } = useGetAllRoleplays();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const activeTab = searchParams.get('activeTab');

  const [tabValue, setTabValue] = useState(activeTab === 'completed' ? 1 : 0);

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const unfinishedRoleplays = useMemo(
    () =>
      rolePlays?.filter((roleplay) => {
        return roleplay.progressStatus !== ProgressStatus.COMPLETED;
      }),
    [rolePlays]
  );

  const completedRoleplays = useMemo(
    () =>
      rolePlays?.filter((roleplay) => {
        return roleplay.progressStatus === ProgressStatus.COMPLETED;
      }),
    [rolePlays]
  );

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    navigate(newValue === 0 ? '?activeTab=your-roleplays' : '?activeTab=completed');
  };

  return (
    <StandaloneContainerStyled>
      {!isTablet && (
        <Typography sx={{ marginBottom: '36px', marginLeft: '12px' }} variant="h3">
          Role-plays
        </Typography>
      )}
      <Box sx={(theme) => ({ borderBottom: 1, borderColor: theme.palette.common.pearl })}>
        <THQTabContainer value={tabValue} onChange={handleChangeTab}>
          <THQTab tabIndex={0} sx={{ marginBottom: '20px', fontWeight: 700 }} label="Not completed" />
          <THQTab tabIndex={1} sx={{ marginBottom: '20px', fontWeight: 700 }} label="Completed" />
        </THQTabContainer>
      </Box>
      <RoleplayListTabPanel roleplays={unfinishedRoleplays} index={0} loading={loading} tabValue={tabValue} />
      <RoleplayListTabPanel roleplays={completedRoleplays} index={1} loading={loading} tabValue={tabValue} />
    </StandaloneContainerStyled>
  );
};
