import React, { useMemo, useState } from 'react';

import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ProgressStatus, THQTab, THQTabContainer } from '@trainhq/trainhq-client-core';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { StandaloneContainerStyled } from '@/components/common/contentBuilderList/styles';
import CourseListTabPanel from '@/components/course/list/CourseListTabPanel';
import { useGetCoursesPage } from '@/hooks/course/useCourseService';

export const CourseList: React.FC = () => {
  const { loadedItems, loading } = useGetCoursesPage({ page: 0, size: 500 });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const activeTab = searchParams.get('activeTab');

  const [tabValue, setTabValue] = useState(activeTab === 'completed' ? 1 : 0);

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const unfinishedCourses = useMemo(
    () => loadedItems?.filter((course) => course.courseStatus !== ProgressStatus.COMPLETED),
    [loadedItems]
  );

  const completedCourses = useMemo(
    () => loadedItems?.filter((course) => course.courseStatus === ProgressStatus.COMPLETED),
    [loadedItems]
  );

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    navigate(newValue === 0 ? '?activeTab=your-courses' : '?activeTab=completed');
  };

  return (
    <>
      <StandaloneContainerStyled>
        {!isTablet && (
          <Typography sx={{ marginBottom: '36px', marginLeft: '12px' }} variant="h3">
            Courses
          </Typography>
        )}
        <Box sx={(theme) => ({ borderBottom: 1, borderColor: theme.palette.common.pearl })}>
          <THQTabContainer value={tabValue} onChange={handleChangeTab}>
            <THQTab tabIndex={0} sx={{ marginBottom: '20px', fontWeight: 700 }} label="Your courses" />
            <THQTab tabIndex={1} sx={{ marginBottom: '20px', fontWeight: 700 }} label="Completed" />
          </THQTabContainer>
        </Box>
        <CourseListTabPanel courses={unfinishedCourses} index={0} loading={loading} tabValue={tabValue} />
        <CourseListTabPanel courses={completedCourses} index={1} loading={loading} tabValue={tabValue} />
      </StandaloneContainerStyled>
    </>
  );
};
