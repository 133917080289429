import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import {
  minutesToHoursAndMinutes,
  THQCloseButton,
  THQIcon,
  THQPrimaryButton,
  THQTooltip,
  TimeIcon,
  useIdle
} from '@trainhq/trainhq-client-core';
import { Outlet, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { ReactComponent as BurgerIcon } from '@/assets/icons/burger-icon.svg';
import { ReactComponent as XFilled } from '@/assets/icons/x.svg';
import { ActiveTab } from '@/components/common/contentBuilderList/ContentBuilderList';
import BorderLinearProgress from '@/components/common/progress/BorderLinearProgress';
import { ProgressTypographyStyled } from '@/components/common/progress/styles';
import { CourseContext } from '@/components/course/courseDetails/context';
import {
  CourseDetailsRootGridStyled,
  CourseTitleTypographyStyled,
  ExpandIconDivStyled,
  IconButtonStyled,
  SidebarRootGridStyled,
  TimeIconStyled,
  TimeSpentInSidebarTextStyled
} from '@/components/course/courseDetails/styles';
import { useCourseInactivityDialogContext } from '@/components/course/inactivityDialog/CourseInactivityDialog';
import SidebarBlocksList from '@/components/course/sidebar/SidebarBlocksList';
import { BLOCK_STRING, COURSE_DETAILS_STRING, COURSES, LIBRARY } from '@/constants/router';
import { useCourseService, useGetCourse } from '@/hooks/course/useCourseService';
import { useLearnerTime, useSpentTime } from '@/hooks/course/useLearnerTime';
import SideBarMain from '@/layouts/sidebar/SideBarMain';
import { LearnerBlock, LearnerCourse } from '@/models/learnerModels';

// TODO: returnTo - check if it can be solved in a nicer more readable and friendly way
// TODO: create better contexts in more optimized way regarding performances.
// To do that, use useReducer and dispatch for big state, split contexts into multiple smaller contexts so common
// below this component don't re-render that often
const CourseDetails: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { blockUuid, courseUuid } = useParams<{ blockUuid: string; courseUuid: string }>();
  // setSearchParams currently does not work in combination with useNavigate() so we are not using it here
  const [searchParams] = useSearchParams();
  const previewModeOn = searchParams.get('preview') == '1';
  const fromLibrary = searchParams.get('library') == '1';
  const returnTo = searchParams.get('returnTo');
  const libraryBackLink = searchParams.get('libraryBackLink');
  const journeyUuid = searchParams.get('journeyUuid');
  const { course, setCourse } = useGetCourse(courseUuid, journeyUuid, previewModeOn);
  const courseInactivityDialog = useCourseInactivityDialogContext();
  const tabToReturn = location.state === ActiveTab.COMPLETED ? '?activeTab=completed' : '?activeTab=your-course';

  // trigger learner time sockets unless preview mode is on and time tracking is enabled
  useLearnerTime(courseUuid, journeyUuid, previewModeOn || !course?.timeTrackingEnabled);

  const [sidebarExpanded, setSidebarExpanded] = useState(true);

  // A flattened array of block with orders modified to represent
  // order in which they are rendered without section nesting
  const allBlocks = useMemo(() => {
    return (
      course?.sections
        .sort((a, b) => a.order - b.order)
        .flatMap((section) => [...section.blocks].sort((a, b) => a.order - b.order))
        .map((block, index) => {
          return { ...block, order: index };
        }) || []
    );
  }, [course?.sections]);

  const currentBlock = useMemo(() => {
    let sectionIndex = 0;
    let blockIndex = 0;
    while (sectionIndex < course?.sections?.length) {
      while (blockIndex < course?.sections?.[sectionIndex]?.blocks?.length) {
        if (course?.currentBlock?.uuid === course?.sections?.[sectionIndex]?.blocks?.[blockIndex]?.uuid) {
          return course?.sections?.[sectionIndex]?.blocks?.[blockIndex];
        }
        blockIndex++;
      }
      sectionIndex++;
      blockIndex = 0;
    }
    // if no block is set as the current block, return the first block
    if (course?.sections?.[0].blocks?.length > 0) {
      return course?.sections?.[0].blocks?.[0];
    }
    //if no blocks can be found return null
    return null;
  }, [course?.currentBlock?.uuid, course?.sections]);

  const selectedBlockUuid = blockUuid || currentBlock?.uuid || course?.sections?.[0]?.blocks?.[0]?.uuid;
  const selectedBlock = allBlocks.find((block) => block.uuid === selectedBlockUuid);
  const nextBlock = useMemo(
    () => allBlocks.find((block) => block?.order === selectedBlock?.order + 1),
    [allBlocks, selectedBlock?.order]
  );
  const previousBlock = useMemo(
    () => allBlocks.find((block) => block?.order === selectedBlock?.order - 1),
    [allBlocks, selectedBlock?.order]
  );

  const courseService = useCourseService(previewModeOn);
  const burgerIcon = useMemo(() => <BurgerIcon />, []);
  const xFilledIcon = useMemo(() => <XFilled />, []);

  const isLast = selectedBlock?.order === allBlocks.length - 1;
  const isFirst = selectedBlock?.order === 0;

  const currentProgress = course?.progressPercentage || 0;

  const isBlockLocked = useCallback(
    (section, block) => {
      if (previewModeOn) {
        return false;
      }
      if (
        section.order < course?.currentSection?.order ||
        (section.order === course?.currentSection?.order && block?.order < currentBlock?.order)
      ) {
        return false;
      }

      let doneWithFirstSection = false;
      let reachedBlock = false;
      for (let i = course?.currentSection?.order ?? 0; i <= section.order; i++) {
        for (let j = doneWithFirstSection ? 0 : currentBlock?.order; j < course?.sections[i]?.blocks.length; j++) {
          if (course?.sections[i]?.blocks[j] === block) {
            reachedBlock = true;
            break;
          }
          if (['QUIZ', 'MULTIPLE_CHOICE_QUIZ'].includes(course?.sections[i]?.blocks[j].blockTemplate.blockType)) {
            return true;
          }
        }
        if (reachedBlock) {
          break;
        }
        doneWithFirstSection = true;
      }
      return false;
    },
    [currentBlock, course?.currentSection?.order, course?.sections]
  );

  const goToBlock = useCallback(
    (block: LearnerBlock, forceIfCurrent = false, newBlockStatus?: string) => {
      const selectedBlockRef = selectedBlock;
      if (!block) {
        navigate({
          pathname: `${COURSE_DETAILS_STRING}/${courseUuid}${BLOCK_STRING}/${selectedBlockRef.uuid}`,
          search: `?preview=${previewModeOn ? '1' : '0'}${journeyUuid ? '&journeyUuid=' + journeyUuid : ''}`
        });
        return;
      }
      if (!forceIfCurrent && selectedBlockRef?.uuid === block?.uuid) return;
      if (block) {
        navigate(
          `${COURSE_DETAILS_STRING}/${courseUuid}${BLOCK_STRING}/${block.uuid}${
            returnTo
              ? `?library=${fromLibrary ? '1' : '0'}&preview=${previewModeOn ? '1' : '0'}&returnTo=${returnTo}${
                  journeyUuid ? '&journeyUuid=' + journeyUuid : ''
                }${libraryBackLink ? `&libraryBackLink=${libraryBackLink}` : ''}`
              : `?library=${fromLibrary ? '1' : '0'}&preview=${previewModeOn ? '1' : '0'}${
                  journeyUuid ? '&journeyUuid=' + journeyUuid : ''
                }`
          }`
        );
        // Check if this block is by order after in flattened array to avoid reverting currentBlock to one already visited
        const flattenedCurrentBlock = allBlocks.find((flatBlock) => currentBlock?.uuid === flatBlock.uuid);

        //TODO extract to separate function so it's not called for no reason when a block has already been completed
        if ((!flattenedCurrentBlock && block.order === 0) || block.order >= flattenedCurrentBlock?.order) {
          courseService.setCourseProgress(courseUuid, block.uuid, journeyUuid).subscribe({
            next: (updatedCourse) => {
              const sortedSections = [...updatedCourse.sections.sort((a, b) => a.order - b.order)];
              sortedSections.forEach((section) => section.blocks.sort((a, b) => a.order - b.order));
              const newCourse: LearnerCourse = {
                ...updatedCourse,
                currentSection: updatedCourse.sections.find(
                  (section) =>
                    section.blocks.findIndex((block) => block.uuid === updatedCourse.currentBlock.uuid) !== -1
                ),
                currentBlock: updatedCourse.currentBlock,
                sections: sortedSections
              };
              setCourse(newCourse);
            }
          });
        } else if (newBlockStatus) {
          setCourse((c) => ({
            ...c,
            userCompleted: isLast,
            sections: c.sections.map((s) => ({
              ...s,
              blocks: s.blocks.map((b) =>
                selectedBlock.uuid === b.uuid ? { ...b, quizFeedbackStatus: newBlockStatus } : b
              )
            }))
          }));
        }
      }
    },
    [
      selectedBlock,
      navigate,
      courseUuid,
      previewModeOn,
      journeyUuid,
      returnTo,
      fromLibrary,
      allBlocks,
      currentBlock?.uuid,
      courseService,
      setCourse,
      isLast
    ]
  );

  const idleCallback = useCallback(() => {
    if (course?.timeTrackingEnabled && !previewModeOn) {
      courseInactivityDialog(course?.inactivityMinutes);
    }
  }, [course?.inactivityMinutes, course?.timeTrackingEnabled, courseInactivityDialog, previewModeOn]);

  // selects appropriate block
  useEffect(() => {
    if (!selectedBlock || blockUuid) return;
    goToBlock(selectedBlock, true);
  }, [goToBlock, selectedBlock, blockUuid]);

  // start timer for spent time
  const timeSpent = useSpentTime(course?.learningTimeDuration, course?.uuid && !previewModeOn);
  useIdle(course?.timeTrackingEnabled && !previewModeOn ? course?.inactivityMinutes : undefined, idleCallback);

  const handleSetSidebarExpanded = useCallback(() => {
    setSidebarExpanded(!sidebarExpanded);
  }, [sidebarExpanded]);

  const closeCourse = () => {
    if (returnTo) {
      navigate(`${returnTo}${libraryBackLink ? `?libraryBackLink=${libraryBackLink}` : ''}`);
    } else {
      navigate(`${COURSES}${tabToReturn}`);
    }
  };

  const handleLibraryReturn = () => {
    if (returnTo) {
      navigate(`${returnTo}${libraryBackLink ? `?libraryBackLink=${libraryBackLink}` : ''}`);
    } else {
      navigate(LIBRARY);
    }
  };

  // TODO: extract to a component to make maintenance easier and to improve performance
  const sidebarContent = useMemo(
    () =>
      sidebarExpanded && (
        <SidebarRootGridStyled
          // playerPresent={!!selectedBlockDetails?.content?.narrationContent}
          container
          direction="column"
          spacing={2}
        >
          <Grid item>
            <CourseTitleTypographyStyled variant="h4">{course?.name}</CourseTitleTypographyStyled>
          </Grid>
          <Grid sx={{ marginBottom: '40px' }} item>
            {!previewModeOn && (
              <>
                <Box>
                  <BorderLinearProgress sx={{ borderRadius: '2px' }} variant="determinate" value={currentProgress} />
                </Box>
                <Grid container justifyContent="space-between">
                  <Grid item sx={{ marginTop: '12px' }}>
                    <ProgressTypographyStyled>{currentProgress}% complete</ProgressTypographyStyled>
                  </Grid>
                  {isMobile && course?.timeTrackingEnabled && (
                    <Grid item>
                      <Grid alignItems="center" container columnSpacing={1} sx={{ marginTop: '12px' }}>
                        <Grid item sx={{ height: '12px', paddingLeft: 0 }}>
                          <TimeIconStyled />
                        </Grid>
                        <Grid item>
                          <TimeSpentInSidebarTextStyled>
                            {timeSpent ? minutesToHoursAndMinutes(Math.floor(timeSpent / (1000 * 60))) : '0 m'}
                          </TimeSpentInSidebarTextStyled>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </>
            )}
          </Grid>
          <Grid item>
            <Grid container direction="column" spacing={2}>
              {course?.sections.map((section) => (
                <SidebarBlocksList
                  previewModeOn={previewModeOn}
                  isItemLocked={false}
                  currentSectionIndex={course?.currentSection?.order}
                  currentBlockIndex={currentBlock?.order}
                  isSelected={false}
                  key={section.uuid}
                  sidebarItem={section}
                  section={undefined}
                >
                  {section.blocks.map((item) => (
                    <SidebarBlocksList
                      previewModeOn={previewModeOn}
                      isItemLocked={isBlockLocked(section, item)}
                      currentSectionIndex={course?.currentSection?.order}
                      currentBlockIndex={currentBlock?.order}
                      isSelected={item.uuid === selectedBlock?.uuid}
                      key={item.uuid}
                      sidebarItem={item}
                      section={section}
                      onGoToBlock={goToBlock}
                    />
                  ))}
                </SidebarBlocksList>
              ))}
            </Grid>
          </Grid>
        </SidebarRootGridStyled>
      ),
    [
      sidebarExpanded,
      course?.name,
      course?.timeTrackingEnabled,
      course?.sections,
      course?.currentSection?.order,
      previewModeOn,
      currentProgress,
      isMobile,
      timeSpent,
      currentBlock?.order,
      isBlockLocked,
      selectedBlock?.uuid,
      goToBlock
    ]
  );
  // TODO: fix dependency issues

  const courseContextValue = useMemo(
    () => ({
      course,
      currentBlock,
      isFirst,
      isLast,
      nextBlock,
      previousBlock,
      previewModeOn,
      sidebarExpanded,
      timeSpent,
      goToBlock,
      setCourse
    }),
    [
      course,
      currentBlock,
      goToBlock,
      isFirst,
      isLast,
      nextBlock,
      previewModeOn,
      previousBlock,
      timeSpent,
      setCourse,
      sidebarExpanded
    ]
  );

  return (
    <CourseDetailsRootGridStyled container>
      <SideBarMain
        content={sidebarContent}
        expanded={sidebarExpanded}
        toggleExpanded={() => setSidebarExpanded(!sidebarExpanded)}
      />
      <ExpandIconDivStyled location="left">
        <IconButtonStyled onClick={handleSetSidebarExpanded}>
          <THQIcon icon={burgerIcon} />
        </IconButtonStyled>
      </ExpandIconDivStyled>
      {previewModeOn && fromLibrary && (
        <THQCloseButton
          sx={(theme) => ({ [theme.breakpoints.down('sm')]: { right: '24px', top: '24px' } })}
          onClose={handleLibraryReturn}
        />
      )}
      <CourseContext.Provider value={courseContextValue}>
        {/* Renders nested route (BlockDetails.tsx) */}
        <Outlet />
      </CourseContext.Provider>
      {!previewModeOn && (
        <ExpandIconDivStyled location="right">
          <Grid container columnSpacing={1}>
            <Grid item>
              {!isMobile && course?.timeTrackingEnabled && (
                <THQTooltip
                  arrow
                  title={`Learning time for this course is ${minutesToHoursAndMinutes(course?.minimumLearningTime)}.`}
                >
                  <THQPrimaryButton>
                    <Grid alignItems="center" container columnSpacing={1}>
                      <Grid item sx={{ paddingLeft: 0 }}>
                        <TimeIcon />
                      </Grid>
                      <Grid item>
                        <Typography fontSize={14} fontWeight={700}>
                          {timeSpent ? minutesToHoursAndMinutes(Math.floor(timeSpent / (1000 * 60))) : '0 m'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </THQPrimaryButton>
                </THQTooltip>
              )}
            </Grid>
            <Grid item>
              <IconButtonStyled onClick={closeCourse}>
                <THQIcon icon={xFilledIcon} />
              </IconButtonStyled>
            </Grid>
          </Grid>
        </ExpandIconDivStyled>
      )}
    </CourseDetailsRootGridStyled>
  );
};

export default CourseDetails;
